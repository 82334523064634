import { CalendarToday, Star } from '@mui/icons-material'
import { Grid, Paper } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import React, { useEffect, useState } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import { useBoolean } from '../../hooks/useBoolean'
import { RatingInfoModel } from '../../models/RatingInfoModel'
import { getRatingInfoByComboId } from '../../services/ratingService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

interface ExperienceRatingProps {
  comboId?: string
}

export const ExperienceRating = ({ comboId }: ExperienceRatingProps) => {
  const { value: isLoading, onTrue: setLoadingTrue, onFalse: setLoadingFalse } = useBoolean(true)
  const [ratingInfo, setRatingInfo] = useState({} as RatingInfoModel)
  const { setDetails: setAlertDetails } = useAlertContext()

  const getRatingInfoData = async (comboId: string) => {
    try {
      setLoadingTrue()
      const response = await getRatingInfoByComboId(comboId)
      setRatingInfo(response)
      // eslint-disable-next-line
    } catch (exception) {
      setAlertDetails({
        message: 'Please refresh the page and try again.',
        severity: AlertSeverity.ERROR,
      })
    }
    setLoadingFalse()
  }

  useEffect(() => {
    if (comboId) {
      getRatingInfoData(comboId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comboId])

  const experienceRatingKebabMenuItems = [{ label: 'View Rating History', to: '/', disabled: true }]

  return (
    <PhoenixBaseCard
      cardTitle={'Experience Rating'}
      contentPadded={true}
      kebabMenuItems={experienceRatingKebabMenuItems}
    >
      <Grid container padding="8px">
        <FieldGroup label={'Mod Factor'} value={ratingInfo.modFactor} icon={<Star />} isLoading={isLoading} />
        <FieldGroup
          label={'Rating Effective Date'}
          value={ratingInfo.ratingEffectiveDate}
          icon={<CalendarToday />}
          isLoading={isLoading}
        />
        <Grid item xs={5}>
          <FieldGroup
            label={'Status'}
            value={
              <Paper elevation={0} sx={{ pr: 1 / 2 }}>
                <StatusBadge description={ratingInfo.status} />
              </Paper>
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={7}>
          <FieldGroup label={'Result'} value={ratingInfo.result} isLoading={isLoading} />
        </Grid>
        <Grid item xs={5}>
          <FieldGroup label={'Issue Date'} value={ratingInfo.issueDate} isLoading={isLoading} />
        </Grid>
        <Grid item xs={7}>
          <FieldGroup label={'Revision Number'} value={ratingInfo.revisionNumber} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
