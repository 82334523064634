import SplitsModel from './SplitsModel'

export interface UnitStatExposuresResponse {
  totalSubjectPremium: number
  totalStandardPremium: number
  totalStandardPayrollExposure: number
  splits: SplitsModel[]
}
export default UnitStatExposuresResponse

export const BlankExposure: UnitStatExposuresResponse = {
  totalSubjectPremium: 0,
  totalStandardPremium: 0,
  totalStandardPayrollExposure: 0,
  splits: [],
}
