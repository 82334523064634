export interface UnitStatLossRecordsModel {
  claimsTotal: number
  incurredIndemnityTotal: number
  incurredMedicalTotal: number
  paidIndemnityTotal: number
  paidMedicalTotal: number
  alaePaidTotal: number
  records: LossRecord[]
}

export const blankLossRecordResponse: UnitStatLossRecordsModel = {
  claimsTotal: 0,
  incurredIndemnityTotal: 0,
  incurredMedicalTotal: 0,
  paidIndemnityTotal: 0,
  paidMedicalTotal: 0,
  alaePaidTotal: 0,
  records: [],
}

export interface LossRecord {
  unitStatLossId: string
  updateType: string
  claimNumber: string
  accidentDate: string
  class: string
  incurredIndemnity: number
  incurredMedical: number
  paidIndemnity: number
  paidMedical: number
  previous: LossRecord[]
}
