import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { FieldGroup, NoResultsFound, PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import React, { useEffect } from 'react'
import PolicyEmployersLocationsModel from '../../models/PolicyDetails/PolicyDetailsEmployerLocations'
import { getPolicyEmployersLocations } from '../../services/policyService'
import { handleTitleCase } from '../../utils/handleTitleCase'
import PolicyEmployerLocationsColumns from './PolicyEmployerLocationsColumns'

interface PolicyEmployersLocationsProps {
  uuid: string
}
export const PolicyEmployersLocations = ({ uuid }: PolicyEmployersLocationsProps) => {
  const [showNamesOnly, setShowNamesOnly] = React.useState(false)

  const [isLoadingData, setIsLoadingData] = React.useState(false)
  const [data, setData] = React.useState([] as PolicyEmployersLocationsModel[])
  const [error, setError] = React.useState(false)

  useEffect(() => {
    setData([] as PolicyEmployersLocationsModel[])
  }, [])

  useEffect(() => {
    if (error) {
      console.log('Error useEffect')
    }
  }, [error])

  const getPolicyEmployersLocationsData = async () => {
    try {
      setIsLoadingData(true)
      setData([] as PolicyEmployersLocationsModel[])
      const responseData = await getPolicyEmployersLocations(uuid as string)
      if (responseData) {
        setData(responseData)
      }
      setIsLoadingData(false)
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }
  const handleFirstOpen = () => {
    if (data.length === 0) {
      getPolicyEmployersLocationsData()
    }
    setShowNamesOnly(false)
  }

  return (
    <PhoenixBaseCard
      cardTitle={'Employers/Locations'}
      isLoading={isLoadingData}
      collapsible
      onExpand={handleFirstOpen}
      contentPadded
    >
      <Grid
        container
        sx={{
          maxHeight: showNamesOnly ? '450px' : '1010px',
          overflowY: 'auto',
        }}
      >
        {isLoadingData && <PhoenixBaseCard cardTitle={'Employers'} isLoading={isLoadingData} variantType="Secondary" />}
        {!isLoadingData && (!data || data.length === 0) && <NoResultsFound />}
        {!isLoadingData && data && data.length > 0 && (
          <>
            <FormGroup sx={{ paddingLeft: '8px' }}>
              <FormControlLabel
                sx={{
                  fontSize: '10px',
                }}
                control={
                  <Switch
                    id="showNamesOnly-switch"
                    checked={showNamesOnly}
                    onChange={() => setShowNamesOnly(!showNamesOnly)}
                  />
                }
                label={
                  <Typography variant="body1" fontSize={'13px'}>
                    Show Names Only
                  </Typography>
                }
              />
            </FormGroup>

            <Grid item sm={12}>
              {data.map((employer, index) => (
                <PhoenixBaseCard
                  cardTitle={employer.name !== null ? handleTitleCase(employer.name) : '----'}
                  isLoading={isLoadingData}
                  variantType="Secondary"
                  key={index}
                >
                  <Grid container padding={'8px'}>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'Email Address'} value={employer.emailAddress} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'Name Link'} value={employer.nameLink} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'Cont Seq. Number'} value={employer.continuationSequenceNumber} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'FEIN'} value={employer.fein} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'Effective'} value={employer.effectiveDate} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'Expiration'} value={employer.expirationDate} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'Received'} value={employer.receivedDate} />
                    </Grid>
                    <Grid item md={2} sm={3}>
                      <FieldGroup label={'PEO/Client'} value={employer.peoCode} />
                    </Grid>
                  </Grid>
                  {!showNamesOnly && (
                    <Grid item sm={12}>
                      <PhoenixBaseTable columns={PolicyEmployerLocationsColumns} data={employer.locations} />
                    </Grid>
                  )}
                </PhoenixBaseCard>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </PhoenixBaseCard>
  )
}

export default PolicyEmployersLocations
