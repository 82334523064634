import { Link } from '@mui/material'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LossRecord } from '../../../models/UnitStatLossRecordsModels'

/* eslint react/prop-types: 0 */ /* istanbul ignore next */
export const UnitStatLossRecordsTableColumns: MRT_ColumnDef<LossRecord>[] = [
  {
    accessorKey: 'updateType',
    header: 'Update Type',
  },
  {
    accessorKey: 'claimNumber',
    header: 'Claim Number',
    Cell: ({ cell, row }) => (
      <Link
        id={`ClaimNumber-${row.index}`}
        component={RouterLink}
        to={`/claims/${row.original.unitStatLossId}`}
        sx={{
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {/* eslint-disable-next-line */}
        <>{cell.getValue()}</>
      </Link>
    ),
  },
  {
    accessorKey: 'accidentDate',
    header: 'Accident Date',
  },
  {
    accessorKey: 'class',
    header: 'Class',
  },
  {
    accessorKey: 'incurredIndemnity',
    header: 'Incurred Indemnity',
    Cell: ({ cell }) => {
      return (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
            cell.getValue<number>()
          )}
        </>
      )
    },
  },
  {
    accessorKey: 'incurredMedical',
    header: 'Incurred Medical',
    Cell: ({ cell }) => {
      return (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
            cell.getValue<number>()
          )}
        </>
      )
    },
  },
  {
    accessorKey: 'paidIndemnity',
    header: 'Paid Indemnity',
    Cell: ({ cell }) => {
      return (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
            cell.getValue<number>()
          )}
        </>
      )
    },
  },
  {
    accessorKey: 'paidMedical',
    header: 'Paid Medical',
    Cell: ({ cell }) => {
      return (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
            cell.getValue<number>()
          )}
        </>
      )
    },
  },
]
