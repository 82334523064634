import { ClaimDetailsInformationModel } from './ClaimDetailsInformationModel'
import { ClaimDetailsInjuryDescCodesModel } from './ClaimDetailsInjuryDescCodesModel'
import { ClaimDetailsLossConditionsModel } from './ClaimDetailsLossConditionsModel'
import { ClaimDetailsSummaryModel } from './ClaimDetailsSummaryModel'

export interface ClaimDetailsResponse {
  claimSummary: ClaimDetailsSummaryModel
  claimInformation: ClaimDetailsInformationModel
  lossConditions: ClaimDetailsLossConditionsModel
  injuryDescriptionCodes: ClaimDetailsInjuryDescCodesModel
}

export const blankClaimDetailsResponse: ClaimDetailsResponse = {
  claimSummary: {
    updateType: '',
    claimNumber: '',
    accidentDate: '',
    class: '',
  },
  claimInformation: {
    incurredIndemnity: 0,
    paidIndemnity: 0,
    incurredMedical: 0,
    paidMedical: 0,
    paidALAE: 0,
    injuryType: '',
    status: '',
    jurisdictionState: '',
    catastropheType: '',
    mcoType: '',
    lumpSum: false,
    occupationDescription: '',
    fraudClaim: '',
    wcbCaseNumber: '',
    averageWeeklyWage: 0,
  },
  lossConditions: {
    lossAct: '',
    lossType: '',
    recoveryType: '',
    claimType: '',
    settlementType: '',
  },
  injuryDescriptionCodes: {
    partOfInjury: '',
    natureOfInjury: '',
    causeOfInjury: '',
  },
}
