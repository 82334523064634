import { CreditCard } from '@mui/icons-material'
import { Divider, Grid } from '@mui/material'
import { FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import React, { useEffect, useState } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import SplitsModel from '../../models/SplitsModel'
import UnitStatExposuresResponse, { BlankExposure } from '../../models/UnitStatExposuresModel'
import { getUnitStatExposures } from '../../services/unitStatService'
import { formatMoney } from '../../utils/moneyFormatter'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import SplitComponent from './SplitComponent/SplitComponent'

interface ExposureProps {
  uuid: string
}

export const UnitStatExposure = ({ uuid }: ExposureProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [exposuresData, setExposuresData] = useState<UnitStatExposuresResponse>(BlankExposure)
  const [error, setError] = React.useState(false)
  const { setDetails: setAlertDetails } = useAlertContext()

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      console.log('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onExpand = async () => {
    try {
      setIsLoading(true)
      const exposuresResponseData = await getUnitStatExposures(uuid as string)

      if (exposuresResponseData) {
        setExposuresData(exposuresResponseData)
        setHasLoaded(true)
      }
      setIsLoading(false)
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }

  const handleFirstOpen = () => {
    if (!hasLoaded) {
      onExpand()
    }
  }

  return (
    <div style={{ marginTop: '8px' }}>
      <PhoenixBaseCard collapsible={true} onExpand={handleFirstOpen} cardTitle="Exposure" isLoading={isLoading}>
        {exposuresData.splits.length <= 0 ? (
          <NoResultsFound />
        ) : (
          <>
            {exposuresData.splits.map((splitObject, index) => {
              return <SplitComponent splitObject={splitObject as SplitsModel} key={index} />
            })}
          </>
        )}
        <Divider
          variant="fullWidth"
          sx={{
            margin: '8px 8px 4px 8px',
            width: '99%',
            borderColor: 'divider',
          }}
        />
        <div style={{ marginLeft: '4px', marginRight: '4px', width: '100%' }}>
          <PhoenixBaseCard variantType="Secondary" cardTitle={'Reported Totals'}>
            <Grid
              paddingLeft={'8px'}
              paddingTop={'8px'}
              flexDirection={'row'}
              container
              spacing={1}
              marginBottom={'16px'}
            >
              <Grid item sx={{ marginLeft: '16px', marginTop: '16px' }} xs={3.5}>
                <FieldGroup
                  label={'TOTAL SUBJECT PREMIUM AMOUNT'}
                  value={formatMoney(exposuresData.totalSubjectPremium)}
                  icon={<CreditCard />}
                />
              </Grid>
              <Grid item sx={{ marginTop: '16px' }} xs={3.5}>
                <FieldGroup
                  label={'TOTAL STANDARD PAYROLL EXPOSURE'}
                  value={formatMoney(exposuresData.totalStandardPayrollExposure)}
                  icon={<CreditCard />}
                />
              </Grid>
              <Grid item sx={{ marginLeft: '16px', marginTop: '16px' }} xs={4}>
                <FieldGroup
                  label={'TOTAL STANDARD PREMIUM AMOUNT'}
                  value={formatMoney(exposuresData.totalStandardPremium)}
                  icon={<CreditCard />}
                />
              </Grid>
            </Grid>
          </PhoenixBaseCard>
        </div>
      </PhoenixBaseCard>
    </div>
  )
}
export default UnitStatExposure
