import { BreadcrumbBar, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ExperienceRating } from '../components/ExperienceRating'
import Exposure from '../components/Exposure'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import PolicyCancellationsReinstatements from '../components/PolicyCancellationsReinstatements'
import { PolicyDetailsContent } from '../components/PolicyDetails'
import PolicyErrorStatusBanner from '../components/PolicyDetails/PolicyErrorStatusBanner'
import PolicyEmployersLocations from '../components/PolicyEmployersLocations'
import { PolicyEndorsementsContent } from '../components/PolicyEndorsementsContent'
import PolicyErrors from '../components/PolicyErrors'
import { PolicyPremiumsContent } from '../components/PolicyPremiumsContent'
import RelateCoverageIDsCard from '../components/RelatedCoverageIDs'
import UnitStatReportsTable from '../components/UnitStatReports/UnitStatReportsTable'
import { useCoverageData } from '../contexts/CoverageDataContext'
import { useHierarchy } from '../contexts/HierarchyContext'
import { useNavbar } from '../contexts/NavbarContext'
import { usePolicyDetailsData } from '../contexts/PolicyDataProvider'
import { Ancestor } from '../models/AncestorResponse'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const PolicyDetail: React.FC = () => {
  const { policyId } = useParams()
  const { setActivePolicyNumber, activePolicyNumber } = usePolicyDetailsData()
  const { directHierarchyChange, ancestors } = useHierarchy()
  const { populateCoverageData } = useCoverageData()
  const { marginLeft } = useNavbar()
  useEffect(() => {
    directHierarchyChange(policyId as UUID, HierarchyTypes.POLICY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId])

  useEffect(() => {
    populateCoverageData(ancestors.combo?.guid as UUID, ancestors.coverage?.guid)
  }, [ancestors.combo, ancestors.coverage, populateCoverageData])

  useEffect(() => {
    if (!activePolicyNumber) {
      setActivePolicyNumber(policyId as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (policyId) {
      setActivePolicyNumber(policyId)
    }
    return () => {
      setActivePolicyNumber('')
    }
    //eslint-disable-next-line
  }, [policyId])

  //Check if data is already loaded when accordion is expanded

  const policyDetailsKebabMenuItems = [
    { label: 'View Unit Stat Reports', to: `/policies/${activePolicyNumber}/unitstats` },
  ]
  return (
    <div
      style={{
        transition: 'margin-left 0.2s',
        marginLeft: marginLeft,
        marginRight: '24px',
        marginTop: '80px',
      }}
    >
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestors.combo?.identifier as string}`,
            to: `/combos/${ancestors.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestors.coverage?.identifier}`,
            to: `/coverages/${ancestors.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestors.policy?.identifier}`,
            to: `/policies/${activePolicyNumber}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <div>
            <PolicyErrorStatusBanner />
            <PhoenixBaseCard cardTitle={'Policy Details'} contentPadded kebabMenuItems={policyDetailsKebabMenuItems}>
              <PolicyDetailsContent />
            </PhoenixBaseCard>
            <div style={{ paddingTop: '8px' }}>
              <PolicyPremiumsContent uuid={policyId as string} />
            </div>
            <div style={{ paddingTop: '8px' }}>
              <Exposure uuid={policyId as string} />
            </div>
            <div style={{ paddingTop: '8px' }}>
              <PolicyEmployersLocations uuid={policyId as string} />
            </div>
            <div style={{ paddingTop: '8px' }}>
              <PolicyCancellationsReinstatements uuid={policyId as string} />
            </div>
            <div style={{ paddingTop: '8px' }}>
              <PolicyEndorsementsContent uuid={policyId as string} />
            </div>
            <div style={{ paddingTop: '8px' }}>
              <UnitStatReportsTable policyID={policyId as string} />
            </div>
            <div style={{ paddingTop: '8px' }}>
              <PolicyErrors policyID={policyId as UUID} />
            </div>
          </div>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key={'cgh'}
            combo={ancestors?.combo as Ancestor}
            coverage={ancestors?.coverage as Ancestor}
          />,
          <ExperienceRating comboId={ancestors?.combo?.identifier} key="er" />,
          <RelateCoverageIDsCard isLoading={false} key="rcic" />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}

export default PolicyDetail
