import React, { ReactNode, createContext, useContext, useState } from 'react'
import {
  EmptyPolicyDetailsPrimaryModel,
  PolicyDetailsPrimaryModel,
} from '../models/PolicyDetails/PolicyDetailsPrimaryModel'
import {
  EmptyPolicyDetailsResponse,
  PolicyDetailsResponseModel,
} from '../models/PolicyDetails/PolicyDetailsResponseModel'

export interface PolicyDetailsDataContextProps {
  activePolicyNumber: string
  setActivePolicyNumber: React.Dispatch<React.SetStateAction<string>>
  policySummaryData: PolicyDetailsResponseModel
  setPolicySummaryData: React.Dispatch<React.SetStateAction<PolicyDetailsResponseModel>>
  policyPrimaryNameData: PolicyDetailsPrimaryModel
  setPolicyPrimaryNameData: React.Dispatch<React.SetStateAction<PolicyDetailsPrimaryModel>>
  isLoadingSummaryData: boolean
  setIsLoadingSummaryData: React.Dispatch<React.SetStateAction<boolean>>
  isLoadingPrimaryNameData: boolean
  setIsLoadingPrimaryNameData: React.Dispatch<React.SetStateAction<boolean>>
  error: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
}

export const PolicyDetailsDataContext = createContext<PolicyDetailsDataContextProps | undefined>(undefined)

interface PolicyDetailsDataProviderProps {
  children: ReactNode
}

const PolicyDetailsDataProvider: React.FC<PolicyDetailsDataProviderProps> = ({ children }) => {
  const [activePolicyNumber, setActivePolicyNumber] = useState('')
  const [policySummaryData, setPolicySummaryData] = useState<PolicyDetailsResponseModel>(EmptyPolicyDetailsResponse)
  const [policyPrimaryNameData, setPolicyPrimaryNameData] =
    useState<PolicyDetailsPrimaryModel>(EmptyPolicyDetailsPrimaryModel)
  const [isLoadingSummaryData, setIsLoadingSummaryData] = useState(false)
  const [isLoadingPrimaryNameData, setIsLoadingPrimaryNameData] = useState(false)
  const [error, setError] = useState(false)

  return (
    <PolicyDetailsDataContext.Provider
      value={{
        activePolicyNumber,
        setActivePolicyNumber,
        policySummaryData,
        setPolicySummaryData,
        policyPrimaryNameData,
        setPolicyPrimaryNameData,
        isLoadingSummaryData,
        setIsLoadingSummaryData,
        isLoadingPrimaryNameData,
        setIsLoadingPrimaryNameData,
        error,
        setError,
      }}
    >
      {children}
    </PolicyDetailsDataContext.Provider>
  )
}

const usePolicyDetailsData = (): PolicyDetailsDataContextProps => {
  const context = useContext(PolicyDetailsDataContext)

  if (!context) {
    throw new Error('usePolicyDetailsData must be used within a PolicyDetailsDataProvider')
  }

  return context
}

export { PolicyDetailsDataProvider, usePolicyDetailsData }
