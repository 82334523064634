import { DataFieldBlankValue } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import PolicyDetailsCancellationsReintstatementsModel from '../../../models/PolicyDetails/PolicyDetailsCancellationsReintstatementsModel'

/* eslint react/prop-types: 0 */
export const PolicyDetailsCancellationsColumns: MRT_ColumnDef<PolicyDetailsCancellationsReintstatementsModel>[] = [
  {
    accessorKey: 'type',
    header: 'TYPE',
    Cell: ({ cell }) => <>{cell.getValue() ? cell.getValue() : <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'effectiveDate',
    header: 'EFFECTIVE',
  },
  {
    accessorKey: 'receivedDate',
    header: 'RECEIVED',
  },
  {
    accessorKey: 'mailedDate',
    header: 'CANCELLATION MAILED',
  },
  {
    accessorKey: 'enteredDate',
    header: 'ENTERED',
  },
  {
    accessorKey: 'reason',
    header: 'REASON CODE- DESCRIPTION',
    size: 200,
  },
  {
    accessorKey: 'issuedDate',
    header: 'ISSUED',
  },
]
export default PolicyDetailsCancellationsColumns
