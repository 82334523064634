import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material'
import React from 'react'

export const CoverageGroupHeaderLoadingSkeleton = () => {
  return (
    <Card
      sx={{
        // minHeight: '300px',
        marginTop: '16px',
        flexShrink: 0,
        borderRadius: 'var(--1, 8px)',
        backgroundColor: '#fafafa',
        marginBottom: '16px',
      }}
    >
      {' '}
      <CardHeader sx={{ backgroundColor: '#e0e0e0' }} title={''} />
      <CardContent sx={{ paddingTop: '4px', paddingLeft: '16px', paddingRight: '8px' }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'100px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'140px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
          <Grid item md={6}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'100px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'140px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item sx={{ marginRight: '10px', marginTop: '6px' }}>
                <Skeleton
                  variant="circular"
                  height={'25px'}
                  width={'25px'}
                  sx={{ marginTop: '6px', marginBottom: '6px' }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rounded"
                  height={'20px'}
                  width={'100px'}
                  sx={{ marginTop: '6px', marginBottom: '6px' }}
                />
                <Skeleton
                  variant="rounded"
                  height={'20px'}
                  width={'140px'}
                  sx={{ marginTop: '5px', marginBottom: '5px' }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sx={{ marginRight: '10px', marginTop: '6px' }}>
                <Skeleton
                  variant="circular"
                  height={'25px'}
                  width={'25px'}
                  sx={{ marginTop: '6px', marginBottom: '6px' }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rounded"
                  height={'20px'}
                  width={'100px'}
                  sx={{ marginTop: '6px', marginBottom: '6px' }}
                />
                <Skeleton
                  variant="rounded"
                  height={'20px'}
                  width={'140px'}
                  sx={{ marginTop: '5px', marginBottom: '5px' }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sx={{ marginRight: '10px', marginTop: '6px' }}>
                <Skeleton
                  variant="circular"
                  height={'25px'}
                  width={'25px'}
                  sx={{ marginTop: '6px', marginBottom: '6px' }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rounded"
                  height={'20px'}
                  width={'100px'}
                  sx={{ marginTop: '6px', marginBottom: '6px' }}
                />
                <Skeleton
                  variant="rounded"
                  height={'20px'}
                  width={'140px'}
                  sx={{ marginTop: '5px', marginBottom: '5px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
