import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import { ClaimDetailsInformationModel } from '../../models/ClaimDetails/ClaimDetailsInformationModel'
import { booleanToYN } from '../../utils/booleanToYN'
import { formatMoney } from '../../utils/moneyFormatter'
import { StateCodes } from '../../utils/stateCodes'

interface ClaimDetailsInformationCardProps {
  data: ClaimDetailsInformationModel
  isLoading?: boolean
}

export const ClaimDetailsInformationCard: React.FC<ClaimDetailsInformationCardProps> = ({
  data,
  isLoading,
}: ClaimDetailsInformationCardProps) => {
  return (
    <PhoenixBaseCard variantType="Secondary" isLoading={isLoading} cardTitle="Claim Information" contentPadded>
      <Grid container spacing={1} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={2}>
          <FieldGroup label={'Incurred Indemnity'} value={formatMoney(data?.incurredIndemnity)} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Paid Indemnity'} value={formatMoney(data?.paidIndemnity)} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Incurred Medical'} value={formatMoney(data?.incurredMedical)} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Paid Medical'} value={formatMoney(data?.paidMedical)} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Paid ALAE'} value={formatMoney(data?.paidALAE)} />
        </Grid>
        <Grid item sm={2}>
          <FieldGroup label={'Injury Type'} value={data?.injuryType} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Status'} value={data?.status} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Jurisdiction State'} value={StateCodes[data?.jurisdictionState]} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Catastrophe Type'} value={data?.catastropheType} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'MCO Type'} value={data?.mcoType} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Lump Sum'} value={booleanToYN(data?.lumpSum)} />
        </Grid>
        <Grid item sm={4.8}>
          <FieldGroup label={'Occupation Description'} value={data?.occupationDescription} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Fraud Claim'} value={data?.fraudClaim} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'WCB Case Number'} value={data?.wcbCaseNumber} />
        </Grid>
        <Grid item sm={2.4}>
          <FieldGroup label={'Average Weekly Wage'} value={formatMoney(data?.averageWeeklyWage)} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
