import { PhoenixBaseCard } from 'componix'
import React, { useEffect } from 'react'
import PolicyDetailsPremiumModel from '../../models/PolicyDetails/PolicyDetailsPremiumModel'
import { getPolicyPremiums } from '../../services/policyService'
import PolicyPremiumsTable from './PolicyPremiumsTable'

interface PolicyPremiumsProps {
  uuid: string
}
export const PolicyPremiumsContent = ({ uuid }: PolicyPremiumsProps) => {
  const [isLoadingData, setIsLoadingData] = React.useState(false)
  const [data, setData] = React.useState([] as PolicyDetailsPremiumModel[])
  const [error, setError] = React.useState(false)

  useEffect(() => {
    setData([])
  }, [])

  useEffect(() => {
    if (error) {
      setIsLoadingData(false)
    }
  }, [error])

  /* istanbul ignore next */
  const getPolicyPremiumsData = async () => {
    try {
      setIsLoadingData(true)
      setData([])
      const responseData = await getPolicyPremiums(uuid)
      if (responseData) {
        setData(responseData)
      }
      setIsLoadingData(false)
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }
  /* istanbul ignore next */
  const handleOnExpand = () => {
    if (data.length === 0) {
      getPolicyPremiumsData()
    }
  }

  return (
    <PhoenixBaseCard cardTitle={'Premiums'} collapsible={true} onExpand={handleOnExpand}>
      <PolicyPremiumsTable data={data} isLoading={isLoadingData} />
    </PhoenixBaseCard>
  )
}
export default PolicyPremiumsContent
