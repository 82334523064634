import { UUID } from 'crypto'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHierarchy } from '../../contexts/HierarchyContext'
import { PolicyDetailsDataContext, PolicyDetailsDataContextProps } from '../../contexts/PolicyDataProvider'
import { EmptyPolicyDetailsPrimaryModel } from '../../models/PolicyDetails/PolicyDetailsPrimaryModel'
import { getPolicyPrimaryName, getPolicySummaryDetails } from '../../services/policyService'
import { HierarchyTypes } from '../../utils/hierarchy.enum'
import PolicyDetailsInformationCard from './PolicyDetailsInformationCard'
import { PolicyDetailsPrimaryNameCard } from './PolicyDetailsPrimaryNameCard'
import { PolicyDetailsProducerInformationCard } from './PolicyDetailsProducerInformationCard'
import PolicyDetailsSummaryCard from './PolicyDetailsSummaryCard'

export const PolicyDetailsContent = () => {
  const { policyId } = useParams()
  const {
    isLoadingSummaryData,
    setIsLoadingSummaryData,
    isLoadingPrimaryNameData,
    setIsLoadingPrimaryNameData,
    policySummaryData,
    setPolicySummaryData,
    policyPrimaryNameData,
    setPolicyPrimaryNameData,
    error,
    setError,
  } = useContext(PolicyDetailsDataContext as React.Context<PolicyDetailsDataContextProps>)
  const { addLeafDescendant, ancestors } = useHierarchy()
  let primaryNameCardOpened = false
  useEffect(() => {
    if (
      policySummaryData.summary &&
      policySummaryData.summary.policyNumber &&
      policySummaryData.summary.effectiveDate &&
      (ancestors.policy === null || ancestors.policy?.guid !== policyId)
    ) {
      addLeafDescendant(
        {
          identifier: `${policySummaryData.summary.policyNumber} - ${policySummaryData.summary.effectiveDate}`,
          guid: policyId as UUID,
        },
        HierarchyTypes.POLICY
      )
    }
    //eslint-disable-next-line
  }, [policySummaryData, ancestors])

  useEffect(() => {
    if (error) {
      setIsLoadingSummaryData(false)
    }
    //eslint-disable-next-line
  }, [error])

  const getPrimaryNameData = async () => {
    try {
      setIsLoadingPrimaryNameData(true)
      getPolicyPrimaryName(policyId as string).then((response) => {
        setPolicyPrimaryNameData(response)
        setIsLoadingPrimaryNameData(false)
      })
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }

  useEffect(() => {
    setIsLoadingSummaryData(true)
    getPolicySummaryDetails(policyId as string)
      .then((response) => {
        if (response !== null && response !== undefined) {
          setPolicySummaryData(response)
        }
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setIsLoadingSummaryData(false)
      })
    //eslint-disable-next-line
  }, [policyId])

  //Check if data is already loaded when accordion is expanded
  const handleFirstOpen = () => {
    if (
      policyPrimaryNameData === undefined ||
      (policyPrimaryNameData === EmptyPolicyDetailsPrimaryModel && !primaryNameCardOpened)
    ) {
      //If not, call the api
      getPrimaryNameData()
      primaryNameCardOpened = true
    }
  }

  return (
    <>
      <PolicyDetailsSummaryCard data={policySummaryData.summary} isLoading={isLoadingSummaryData} />
      <PolicyDetailsInformationCard isLoading={isLoadingSummaryData} data={policySummaryData.information} />
      <PolicyDetailsPrimaryNameCard
        isLoading={isLoadingSummaryData}
        isLoadingData={isLoadingPrimaryNameData}
        data={policyPrimaryNameData}
        handleFirstOpen={handleFirstOpen}
      />
      <PolicyDetailsProducerInformationCard isLoading={isLoadingSummaryData} data={policySummaryData.producer} />
    </>
  )
}
export default PolicyDetailsContent
