import type { IPublicClientApplication } from '@azure/msal-browser'

const logoutActiveUser = (instance?: IPublicClientApplication) => {
  if (instance == null) {
    throw Error('There is no valid instance.')
  }
  const activeAccount = instance.getActiveAccount()
  if (!activeAccount) {
    throw Error('There is no active account. Please verify user is logged in.')
  }
  const logoutRequest = {
    account: activeAccount,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
  }
  instance.logoutRedirect(logoutRequest)
}

export default logoutActiveUser
