import { msalInstance } from './msalSetup'

export const getSilentToken = async (): Promise<string | null> => {
  try {
    const account = msalInstance.getActiveAccount()
    if (!account) {
      throw Error('There is no active account found. Please verify the user is logged in.')
    }

    // Scopes might me modified in later stages to handle advanced scenarios
    const silentTokenResponse = await msalInstance.acquireTokenSilent({
      account,
      scopes: [`api://${process.env.REACT_APP_AZURE_APP_REGISTRATION_CLIENT_ID}/default`],
    })

    return silentTokenResponse.accessToken
  } catch (error) {
    console.error('Error obtaining silent token:', error)
    return null
  }
}
