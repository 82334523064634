import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { ClaimDetailsContent } from '../components/ClaimDetails'
import PolicyInfoGutterCard from '../components/ClaimDetails/PolicyInfoGutterCard'
import UnitStatInfoGutterCard from '../components/ClaimDetails/UnitStatInfoGutterCard'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import RelateCoverageIDsCard from '../components/RelatedCoverageIDs'
import { useAlertContext } from '../contexts/AlertContext'
import { useCoverageData } from '../contexts/CoverageDataContext'
import { useHierarchy } from '../contexts/HierarchyContext'
import { useNavbar } from '../contexts/NavbarContext'
import { usePolicyDetailsData } from '../contexts/PolicyDataProvider'
import { Ancestor } from '../models/AncestorResponse'
import { ClaimDetailsResponse, blankClaimDetailsResponse } from '../models/ClaimDetails/ClaimDetailsResponse'
import { EmptyPolicyDetailsResponse } from '../models/PolicyDetails/PolicyDetailsResponseModel'
import UnitStatDetailsModel, { blankUnitStatDetailsModel } from '../models/UnitStatDetailsModel'
import { getClaimDetails } from '../services/claimService'
import { getPolicySummaryDetails } from '../services/policyService'
import { getUnitStatDetails } from '../services/unitStatService'
import { HierarchyTypes } from '../utils/hierarchy.enum'

export const ClaimDetails = () => {
  const { unitStatLossId } = useParams()
  const { marginLeft } = useNavbar()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [claimDetailsData, setClaimDetailsData] = useState<ClaimDetailsResponse>(blankClaimDetailsResponse)

  const [isLoadingUnitStatInfo, setIsLoadingUnitStatInfo] = useState(true)
  const [unitStatDetails, setUnitStatDetails] = useState<UnitStatDetailsModel>(
    blankUnitStatDetailsModel as UnitStatDetailsModel
  )
  const { setDetails: setAlertDetails } = useAlertContext()
  const { ancestors, directHierarchyChange } = useHierarchy()
  const { populateCoverageData } = useCoverageData()
  const { policySummaryData, setPolicySummaryData, isLoadingSummaryData, setIsLoadingSummaryData } =
    usePolicyDetailsData()

  useEffect(() => {
    populateCoverageData(ancestors.combo?.guid as UUID, ancestors.coverage?.guid)
  }, [ancestors.combo, ancestors.coverage, populateCoverageData])

  /* istanbul ignore next */
  useEffect(() => {
    if (policySummaryData == EmptyPolicyDetailsResponse && ancestors.policy?.guid != undefined) {
      setIsLoadingSummaryData(true)
      getPolicySummaryDetails(ancestors.policy?.guid as UUID).then((response) => {
        if (response !== null && response !== undefined) {
          setPolicySummaryData(response)
          setIsLoadingSummaryData(false)
        }
      })
    }
  }, [policySummaryData, ancestors.policy?.guid])

  /* istanbul ignore next */
  useEffect(() => {
    if (ancestors.unitStat?.guid != undefined) {
      setIsLoadingUnitStatInfo(true)
      getUnitStatDetails(ancestors.unitStat?.guid as string).then((response) => {
        setUnitStatDetails(response)
        setIsLoadingUnitStatInfo(false)
      })
    }
  }, [ancestors.unitStat?.guid])

  useEffect(() => {
    directHierarchyChange(unitStatLossId as UUID, HierarchyTypes.CLAIM)
  }, [unitStatLossId])

  /* istanbul ignore next */
  useEffect(() => {
    if (unitStatLossId !== undefined && ancestors.unitStat?.guid !== undefined) {
      setIsLoadingData(true)
      getClaimDetails(unitStatLossId, ancestors.unitStat?.guid as string)
        .then((response) => {
          if (response !== null && response !== undefined) {
            setClaimDetailsData(response)
          }
        })
        .catch(() => {
          setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
        })
        .finally(() => {
          setIsLoadingData(false)
        })
    }
  }, [unitStatLossId, ancestors.unitStat?.guid])

  return (
    <div style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestors.combo?.identifier as string}`,
            to: `/combos/${ancestors.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestors.coverage?.identifier}`,
            to: `/coverages/${ancestors.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestors.policy?.identifier}`,
            to: `/policies/${ancestors.policy?.guid}`,
          },
          {
            label: `Unit Stat Report ${ancestors.unitStat?.identifier}`,
            to: `/unitstats/${ancestors.unitStat?.guid}`,
          },
          {
            label: `Claim ${claimDetailsData?.claimSummary?.claimNumber} - ${claimDetailsData?.claimSummary?.accidentDate}`,
            to: `/claims/${unitStatLossId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={<ClaimDetailsContent data={claimDetailsData} isLoading={isLoadingData}></ClaimDetailsContent>}
        sideRailComponentArray={[
          <UnitStatInfoGutterCard
            key={'usigc'}
            unitStat={unitStatDetails}
            uuid={ancestors.unitStat?.guid as UUID}
            isLoading={isLoadingUnitStatInfo}
          />,
          <PolicyInfoGutterCard
            key="pigc"
            policy={policySummaryData}
            guid={ancestors.policy?.guid as UUID}
            isLoading={isLoadingSummaryData}
          />,
          <PrimaryCoverageIdentifier
            key={'cgh'}
            combo={ancestors.combo as Ancestor}
            coverage={ancestors.coverage as Ancestor}
          />,
          <RelateCoverageIDsCard isLoading={false} key="rcic" />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
