import { ErrorResponseModel } from '../models/PolicyDetails/PolicyDetailsErrorModel'
import { UpdateErrorsModel } from '../models/PolicyDetails/UpdateErrorsModel'
import { UnitStatDeductibleModel } from '../models/UnitStatDeductibleModel'
import UnitStatDetailsModel from '../models/UnitStatDetailsModel'
import UnitStatExposuresResponse from '../models/UnitStatExposuresModel'
import { UnitStatLossRecordsModel } from '../models/UnitStatLossRecordsModels'
import UnitStatPolicyConditionsModel from '../models/UnitStatPolicyConditionsModel'
import UnitStatPolicyInformationModel from '../models/UnitStatPolicyInfoModel'
import { UnitStatPolicyTypeModel } from '../models/UnitStatPolicyTypeModel'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getUnitStatDetails = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatDetailsModel> = await api.get(`${Endpoints.UnitStat}/${unitStatId}`)
  return response.data
}

export const getUnitStatPolicyInfo = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatPolicyInformationModel> = await api.get(
    `${Endpoints.UnitStat}/${unitStatId}/policy-info`
  )
  return response.data
}

export const getUnitStatPolicyConditions = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatPolicyConditionsModel> = await api.get(
    `${Endpoints.UnitStat}/${unitStatId}/policy-conditions`
  )
  return response.data
}

export const getUnitStatExposures = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatExposuresResponse> = await api.get(
    `${Endpoints.UnitStat}/${unitStatId}/exposures`
  )
  return response.data
}

export const getUnitStatPolicyType = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatPolicyTypeModel> = await api.get(
    `${Endpoints.UnitStat}/${unitStatId}/policy-type`
  )
  return response.data
}

export const getUnitStatDeductible = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatDeductibleModel> = await api.get(`${Endpoints.UnitStat}/${unitStatId}/deductible`)
  return response.data
}

export const getUnitStatLossRecords = async (unitStatId: string) => {
  const response: ApiResponse<UnitStatLossRecordsModel> = await api.get(`${Endpoints.UnitStat}/${unitStatId}/losses`)
  return response.data
}

export const getUnitStatErrors = async (unitStatId: string): Promise<ErrorResponseModel> => {
  const response: ApiResponse<ErrorResponseModel> = await api.get(`${Endpoints.UnitStat}/${unitStatId}/errors`)
  return response.data
}

export const updateUnitStatErrors = async (unitStatID: string, body: UpdateErrorsModel) => {
  const response = await api.post(`${Endpoints.UnitStat}/${unitStatID}/errors`, {
    editIDs: body.editIDs,
    userAccepted: body.userAccepted,
    responseText: body.responseText,
  })

  return response.data
}
