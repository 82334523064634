import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import React from 'react'
import PolicyDetailSummaryModel from '../../models/PolicyDetails/PolicyDetailSummaryModel'

interface PolicyDetailsSummaryCardProps {
  data: PolicyDetailSummaryModel
  isLoading?: boolean
}

export const PolicyDetailsSummaryCard = ({ isLoading, data }: PolicyDetailsSummaryCardProps) => {
  const gridItemHeight = '64px'
  const itemBreakPointVars = {
    xs: 12,
    sm: 4,
  }

  return (
    <PhoenixBaseCard
      variantType="Secondary"
      isLoading={isLoading}
      cardTitle="Policy Summary"
      contentPadded
      defaultWidth={6}
    >
      <Grid container spacing={1} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={itemBreakPointVars.sm}>
          <FieldGroup label={'Carrier ID'} value={data?.carrierId} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Carrier Name'} value={data?.carrierName} multiLineWrap />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Policy Number'} value={data?.policyNumber} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Effective Date'} value={data?.effectiveDate} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Expiration Date'} value={data?.expirationDate} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Issue Date'} value={data?.issueDate} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup
            label={'Status'}
            value={
              <Grid item>
                <StatusBadge description={data?.status as string} />
              </Grid>
            }
          />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight}>
          <FieldGroup label={'Status Date'} value={data?.statusDate} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight}>
          <FieldGroup label={'Received Date'} value={data?.receivedDate} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
export default PolicyDetailsSummaryCard
