import { DataFieldBlankValue } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import PolicyDetailsPremiumModel from '../../../models/PolicyDetails/PolicyDetailsPremiumModel'
import { formatMoney } from '../../../utils/moneyFormatter'

/* eslint react/prop-types: 0 */ /* istanbul ignore next */
export const PolicyPremiumsTableColumns: MRT_ColumnDef<PolicyDetailsPremiumModel>[] = [
  {
    accessorKey: 'periodEffective',
    header: 'Period Effective',
  },
  {
    accessorKey: 'totalEstimatedStandardPremium',
    header: 'Tot Est STND Premium',
    size: 190,
    Cell: ({ cell }) => <>{formatMoney(cell.getValue<number>()) ?? <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'ratingEffective',
    header: 'Rating Effective',
  },
  {
    accessorKey: 'modEffective',
    header: 'Mod Effective',
  },
  {
    accessorKey: 'modFactor',
    header: 'Mod Factor',
  },
  {
    accessorKey: 'premiumDiscount',
    header: 'Premium Discount',
    Cell: ({ cell }) => <>{formatMoney(cell.getValue<number>()) ?? <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'sifCreditDebit',
    header: 'SIF Credit/Debit',
    Cell: ({ cell }) => <>{formatMoney(cell.getValue<number>()) ?? <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'statePremium',
    header: 'State Premium',
    Cell: ({ cell }) => <>{formatMoney(cell.getValue<number>()) ?? <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'changeEffective',
    header: 'Change Effective',
  },
  {
    accessorKey: 'changeExpiration',
    header: 'Change Expiration',
  },
]

export default PolicyPremiumsTableColumns
