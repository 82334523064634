// eslint-disable-next-line
import { ErrorResponseModel } from '../../models/PolicyDetails/PolicyDetailsErrorModel'

export const PolicyDetailsErrorsMock: ErrorResponseModel[] = [
  {
    transactionCode: '02',
    date: '12/12/2020',
    noticeDate: '12/12/2020',
    fineDueDate: '12/12/2020',
    fineStatus: 'Fine',
    fineStatusDate: '12/12/2020',
    errors: [
      {
        guid: 'fd74d39f-cc54-457d-9115-2fa4a8b0fa02',
        editID: '1234',
        errorMessage: 'Error',
        resolvedDate: '12/12/2020',
        status: 'Resolved',
        responses: [
          {
            date: '12/12/2020',
            time: '12:00PM',
            source: 'Carrier',
            message:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            attachments: [
              { name: 'file1', path: '/file1.pdf' },
              { name: 'file2', path: '/file2.pdf' },
            ],
          },
          {
            date: '12/11/2020',
            time: '12:00PM',
            source: 'Nycirb',
            userName: 'User',
            message: 'User response goes here.',
          },
          {
            date: '12/10/2020',
            time: '12:00PM',
            source: 'Carrier',
            message: 'Carrier message goes here.',
          },
        ],
      },
      {
        guid: 'fd74d39f-cc54-457d-9115-2fa4a8b0fa03',
        editID: '1233',
        errorMessage: 'Error',
        resolvedDate: '----',
        status: 'Outstanding',
        responses: [
          {
            date: '12/12/2020',
            time: '12:00PM',
            source: 'Carrier',
            message:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            attachments: [
              { name: 'file1', path: '/file1.pdf' },
              { name: 'file2', path: '/file2.pdf' },
            ],
          },
          {
            date: '12/11/2020',
            time: '12:00PM',
            source: 'Nycirb',
            userName: 'User',
            message: 'User response goes here.',
          },
          {
            date: '12/10/2020',
            time: '12:00PM',
            source: 'Carrier',
            message: 'Carrier message goes here.',
          },
        ],
      },
      {
        guid: 'fd74d39f-cc54-457d-9115-2fa4a8b0fa04',
        editID: '1235',
        errorMessage: 'Error',
        resolvedDate: '----',
        status: 'Outstanding',
      },
    ],
    guid: 'd4c130b9-5b17-4f80-9ced-59927f357065',
  },
  {
    transactionCode: '05',
    date: '12/12/2020',
    noticeDate: '12/12/2020',
    fineDueDate: '12/12/2020',
    fineStatus: 'Fine',
    fineStatusDate: '12/12/2020',
    errors: [
      {
        guid: 'fd74d39f-cc54-457d-9115-2fa4a8b0fa05',
        editID: '1234',
        errorMessage: 'Error',
        resolvedDate: '12/12/2020',
        status: 'Resolved',
      },
    ],
    guid: 'd4c130b9-5b17-4f80-9ced-59927f357066',
  },
]
