import { CalendarToday } from '@mui/icons-material'
import { Grid, Paper } from '@mui/material'
import { BreadcrumbBar, FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { ExperienceRating } from '../components/ExperienceRating'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import PolicyConditions from '../components/PolicyConditions'
import RelateCoverageIDsCard from '../components/RelatedCoverageIDs'
import UnitStatErrorBanner from '../components/UnitStat/UnitStatErrorBanner'
import UnitStatExposure from '../components/UnitStatExposure/UnitStatExposure'
import LossRecordsSplit from '../components/UnitStatLossRecords/UnitStatLossRecords'
import UnitStatReportsErrors from '../components/UnitStatReports/UnitStatReportsErrors'
import { useAlertContext } from '../contexts/AlertContext'
import { useCoverageData } from '../contexts/CoverageDataContext'
import { useHierarchy } from '../contexts/HierarchyContext'
import { useNavbar } from '../contexts/NavbarContext'
import { Ancestor } from '../models/AncestorResponse'
import { UnitStatDeductibleModel } from '../models/UnitStatDeductibleModel'
import UnitStatDetailsModel from '../models/UnitStatDetailsModel'
import UnitStatPolicyConditionsModel from '../models/UnitStatPolicyConditionsModel'
import UnitStatPolicyInfoModel from '../models/UnitStatPolicyInfoModel'
import { UnitStatPolicyTypeModel } from '../models/UnitStatPolicyTypeModel'
import {
  getUnitStatDeductible,
  getUnitStatDetails,
  getUnitStatPolicyConditions,
  getUnitStatPolicyType,
} from '../services/unitStatService'
import { getUnitStatPolicyInfo } from '../services/unitStatService'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { formatMoney } from '../utils/moneyFormatter'
import { formatPercent } from '../utils/percentFormatter'

export const UnitStatDetails: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const { unitStatId } = useParams()
  const [policyInfoData, setPolicyInfoData] = React.useState({} as UnitStatPolicyInfoModel)
  const [unitStatDetailsData, setUnitStatDetailsData] = React.useState({} as UnitStatDetailsModel)
  const [unitStatConditionsData, setUnitStatConditionsData] = React.useState({} as UnitStatPolicyConditionsModel)
  const [unitStatPolicyTypeData, setUnitStatPolicyTypeData] = React.useState({} as UnitStatPolicyTypeModel)
  const [unitStatDeductibleData, setUnitStatDeductibleData] = React.useState({} as UnitStatDeductibleModel)
  const { marginLeft } = useNavbar()
  const [isPolicyTypeLoading, setIsPolicyTypeLoading] = React.useState(false)
  const [isDeductibleLoading, setIsDeductibleLoading] = React.useState(false)
  const [hasPolicyTypeLoaded, setHasPolicyTypeLoaded] = React.useState(false)
  const [hasDeductibleLoaded, setHasDeductibleLoaded] = React.useState(false)
  const { setDetails: setAlertDetails } = useAlertContext()
  const { directHierarchyChange, ancestors, addLeafDescendant } = useHierarchy()
  const { populateCoverageData } = useCoverageData()

  useEffect(() => {
    populateCoverageData(ancestors.combo?.guid as UUID, ancestors.coverage?.guid)
  }, [ancestors.combo, ancestors.coverage, populateCoverageData])

  useEffect(() => {
    directHierarchyChange(unitStatId as UUID, HierarchyTypes.UNITSTAT)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitStatId])
  useEffect(() => {
    getUnitStatData(unitStatId as string)
    //eslint-disable-next-line
  }, [unitStatId])
  useEffect(() => {
    if (
      (ancestors.unitStat === null || ancestors.unitStat?.guid !== unitStatId) &&
      policyInfoData?.reportLevel &&
      policyInfoData?.correctionSequence
    ) {
      addLeafDescendant(
        {
          guid: unitStatId as UUID,
          identifier: `${policyInfoData.reportLevel} - ${policyInfoData.correctionSequence}`,
        },
        HierarchyTypes.UNITSTAT
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyInfoData, unitStatId, ancestors, isLoadingData])

  /* istanbul ignore next */
  const getUnitStatData = async (unitStatId: string) => {
    try {
      setIsLoadingData(true)
      setPolicyInfoData({} as UnitStatPolicyInfoModel)
      setUnitStatDetailsData({} as UnitStatDetailsModel)
      const policyInfoResponseData = await getUnitStatPolicyInfo(unitStatId)
      const unitStatDetailsResponseData = await getUnitStatDetails(unitStatId)
      const unitStatPolicyConditionsResponseData = await getUnitStatPolicyConditions(unitStatId)

      if (policyInfoResponseData && unitStatDetailsResponseData && unitStatPolicyConditionsResponseData) {
        setPolicyInfoData(policyInfoResponseData)
        setUnitStatDetailsData(unitStatDetailsResponseData)
        setUnitStatConditionsData(unitStatPolicyConditionsResponseData)
      }
      setIsLoadingData(false)
      // eslint-disable-next-line
    } catch (exception) {
      setIsLoadingData(false)
      setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
    }
  }

  return (
    <div style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestors.combo?.identifier as string}`,
            to: `/combos/${ancestors.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestors.coverage?.identifier}`,
            to: `/coverages/${ancestors.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestors.policy?.identifier}`,
            to: `/policies/${ancestors.policy?.guid}`,
          },
          {
            label: `Unit Stat Report ${ancestors.unitStat?.identifier}`,
            to: `/unitstats/${ancestors.unitStat?.guid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <UnitStatErrorBanner outstandingErrors={unitStatDetailsData.outstandingErrors} />
            <PhoenixBaseCard cardTitle="Unit Stat Details" contentPadded={true} isLoading={isLoadingData}>
              <Grid container padding={'8px'} spacing={1}>
                <Grid item xs={3}>
                  <FieldGroup
                    label="STATUS"
                    value={
                      <Paper sx={{ marginTop: '4px' }} elevation={0}>
                        <StatusBadge description={unitStatDetailsData.status} />
                      </Paper>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FieldGroup
                    label="RECEIVED DATE"
                    value={unitStatDetailsData.receivedDate}
                    icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FieldGroup
                    label="ACCEPTED DATE"
                    value={unitStatDetailsData.acceptedDate}
                    icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                  />
                </Grid>
              </Grid>
              <PhoenixBaseCard cardTitle="Policy Information" variantType="Secondary" isLoading={isLoadingData}>
                <Grid container>
                  <Grid item padding={1.5} marginLeft={0} xs={3}>
                    <FieldGroup label={'REPORT LEVEL'} value={policyInfoData.reportLevel} />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup label={'CORRECTION SEQ'} value={policyInfoData.correctionSequence} />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup label={'CORRECTION TYPE'} value={policyInfoData.correctionType} />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup label={'CARRIER ID'} value={policyInfoData.carrierID} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item padding={1.5} marginLeft={0} xs={3}>
                    <FieldGroup
                      label={'POLICY NUMBER'}
                      value={
                        policyInfoData.policyNumber && (
                          <Link
                            id={`PolicyNumberLink`}
                            to={`/policies/${ancestors.policy?.guid}`}
                            style={{
                              textDecoration: 'none',
                              color: '#1976d2',
                              textDecorationLine: 'underline',
                            }}
                          >
                            {policyInfoData.policyNumber}
                          </Link>
                        )
                      }
                    />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup
                      label={'POLICY EFFECTIVE DATE'}
                      value={policyInfoData.policyEffectiveDate}
                      icon={<CalendarToday />}
                    />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup
                      label={'POLICY EXPIRATION DATE'}
                      value={policyInfoData.policyExpirationDate}
                      icon={<CalendarToday />}
                    />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup
                      label={'STATE EFFECTIVE DATE'}
                      value={policyInfoData.stateEffectiveDate}
                      icon={<CalendarToday />}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <PolicyConditions isLoadingData={isLoadingData} unitStatConditionsData={unitStatConditionsData} />
                </Grid>
                <Grid item xs={4}>
                  <PhoenixBaseCard
                    cardTitle="Policy Type"
                    variantType="Secondary"
                    collapsible={true}
                    contentPadded={true}
                    onExpand={
                      /*istanbul ignore next */ () => {
                        if (!hasPolicyTypeLoaded) {
                          setHasPolicyTypeLoaded(true)
                          setIsPolicyTypeLoading(true)
                          getUnitStatPolicyType(unitStatId as string)
                            .then((response) => {
                              setUnitStatPolicyTypeData(response)
                            })
                            .finally(() => setIsPolicyTypeLoading(false))
                            .catch(() => {
                              setIsPolicyTypeLoading(false)
                              setAlertDetails({
                                message: 'Please refresh the page and try again.',
                                severity: AlertSeverity.ERROR,
                              })
                            })
                        }
                      }
                    }
                    isLoading={isPolicyTypeLoading}
                  >
                    <Grid container columnSpacing={1} sx={{ marginLeft: '8px', marginTop: '16px' }}>
                      <Grid item xs={6}>
                        <FieldGroup label="COVERAGE TYPE" value={unitStatPolicyTypeData.coverageType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="PLAN TYPE" value={unitStatPolicyTypeData.planType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="NON STANDARD TYPE" value={unitStatPolicyTypeData.nonStandardType} />
                      </Grid>
                    </Grid>
                  </PhoenixBaseCard>
                </Grid>
                <Grid item xs={4}>
                  <PhoenixBaseCard
                    cardTitle="Deductible"
                    variantType="Secondary"
                    collapsible={true}
                    contentPadded={true}
                    onExpand={() => {
                      /* istanbul ignore next */
                      if (!hasDeductibleLoaded) {
                        setHasDeductibleLoaded(true)
                        setIsDeductibleLoading(true)
                        getUnitStatDeductible(unitStatId as string)
                          .then((response) => {
                            setUnitStatDeductibleData(response)
                          })
                          .finally(() => setIsDeductibleLoading(false))
                          .catch(() => {
                            setIsDeductibleLoading(false)
                            setAlertDetails({
                              message: 'Please refresh the page and try again.',
                              severity: AlertSeverity.ERROR,
                            })
                          })
                      }
                    }}
                    isLoading={isDeductibleLoading}
                  >
                    <Grid container sx={{ marginLeft: '8px', marginTop: '16px' }}>
                      <Grid item xs={6}>
                        <FieldGroup label="DEDUCTIBLE TYPE" value={unitStatDeductibleData.deductibleType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="PLAN TYPE" value={unitStatDeductibleData.appliedType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup
                          label="DEDUCTIBLE PERCENT"
                          value={formatPercent(unitStatDeductibleData.deductiblePercent)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup
                          label="DEDUCTIBLE AMOUNT PER CLAIM/ACCIDENT"
                          value={formatMoney(unitStatDeductibleData.deductibleAmountPer)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup
                          label="DEDUCTIBLE AMOUNT - AGGREGATE"
                          value={formatMoney(unitStatDeductibleData.deductibleAmountAggregate)}
                        />
                      </Grid>
                    </Grid>
                  </PhoenixBaseCard>
                </Grid>
              </PhoenixBaseCard>
            </PhoenixBaseCard>
            <UnitStatExposure uuid={unitStatId as string} />
            <LossRecordsSplit uuid={unitStatId as string} />
            <UnitStatReportsErrors uuid={unitStatId as string} />
          </>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key={'cgh'}
            combo={ancestors.combo as Ancestor}
            coverage={ancestors.coverage as Ancestor}
          />,
          <ExperienceRating comboId={ancestors.combo?.identifier} key="er" />,
          <RelateCoverageIDsCard isLoading={false} key="rcic" />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
export default UnitStatDetails
