import AddressDTOModel, { EmptyAddressDTOModel } from '../AddressDTOModel'

export interface PolicyDetailsPrimaryModel {
  name: string
  address: AddressDTOModel
  email: string
  legalNature: string
  fein: string
}

export const EmptyPolicyDetailsPrimaryModel: PolicyDetailsPrimaryModel = {
  name: '',
  address: EmptyAddressDTOModel,
  email: '',
  legalNature: '',
  fein: '',
}
