import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import React from 'react'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

interface UnitStatErrorBannerProps {
  outstandingErrors: boolean
}

const UnitStatErrorBanner = ({ outstandingErrors }: UnitStatErrorBannerProps) => {
  return outstandingErrors ? (
    <Box pb={1}>
      <Alert key={'alert'} variant="filled" icon={<InfoOutlined />} severity={AlertSeverity.INFO}>
        <Typography>This unit is being tracked for errors.</Typography>
      </Alert>
    </Box>
  ) : null
}

export default UnitStatErrorBanner
