import { CalendarToday, CreditCard, Person, Receipt } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import React, { useState } from 'react'
import { ExposureModel } from '../../models/ExposureModel'
import { getPolicyDetailExposure } from '../../services/policyService'
import LoadingSkeletons from '../LoadingSkeletons/LoadingSkeletons'
import ExposureTable from './ExposureTable/ExposureTable'

interface ExposureProps {
  uuid: string
}

export const Exposure = ({ uuid }: ExposureProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [searchData, setSearchData] = useState({
    summary: {
      periodEffectiveDate: '',
      estimatedPayroll: 0,
      calculatedPremium: 0,
      governingClass: '',
    },
    table: [],
  } as ExposureModel)

  const onExpand = async () => {
    setIsLoading(true)
    getPolicyDetailExposure(uuid as string)
      .then((response) => {
        if (response.status === 200) {
          setSearchData(response.data)
          setHasLoaded(true)
        } else {
          setHasLoaded(false)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleFirstOpen = () => {
    if (!hasLoaded) {
      onExpand()
    }
  }

  return (
    <PhoenixBaseCard collapsible={true} onExpand={handleFirstOpen} cardTitle="Exposure">
      {isLoading ? (
        <LoadingSkeletons />
      ) : searchData.summary.periodEffectiveDate === '' ? (
        <NoResultsFound />
      ) : (
        <>
          <Grid
            paddingLeft={'8px'}
            paddingTop={'8px'}
            flexDirection={'row'}
            container
            spacing={1}
            marginBottom={'16px'}
          >
            <Grid item md={2.3}>
              <FieldGroup
                label={'PERIOD EFFECTIVE DATE'}
                value={searchData?.summary?.periodEffectiveDate}
                icon={<CalendarToday sx={{ marginTop: '6px' }} />}
              />
            </Grid>
            <Grid item md={2}>
              <FieldGroup
                label={'ESTIMATED PAYROLL'}
                value={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                }).format(searchData?.summary?.estimatedPayroll)}
                icon={<Receipt sx={{ marginTop: '6px' }} />}
              />
            </Grid>
            <Grid item md={2.2}>
              <FieldGroup
                label={'CALCULATED PREMIUM'}
                value={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                }).format(searchData?.summary?.calculatedPremium)}
                icon={<CreditCard sx={{ marginTop: '6px' }} />}
              />
            </Grid>
            <Grid item md={2}>
              <FieldGroup
                label={'GOVERNING CLASS'}
                value={searchData?.summary?.governingClass}
                icon={<Person sx={{ marginTop: '6px' }} />}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <ExposureTable isLoading={isLoading} searchData={searchData?.table ?? []} />
            </Grid>
          </Grid>
        </>
      )}
    </PhoenixBaseCard>
  )
}
export default Exposure
