import { UUID } from 'crypto'
import React, { useContext, useState } from 'react'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { Ancestor, AncestorResponse } from '../models/AncestorResponse'
import { getAncestors } from '../services/ancestorsService'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { useAlertContext } from './AlertContext'

const blankAncestry: AncestorResponse = {
  combo: null,
  coverage: null,
  policy: null,
  unitStat: null,
  claim: null,
  rating: null,
}

interface HierarchyContextProps {
  ancestors: AncestorResponse
  addLeafDescendant: (descedant: Ancestor, type: HierarchyTypes) => void
  directHierarchyChange: (uuid: UUID, type: HierarchyTypes) => void
  clearAncestors: () => void
}

export const HierarchyContext = React.createContext<HierarchyContextProps | undefined>(undefined)

const HierarchyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ancestors, setAncestors] = useState<AncestorResponse>(blankAncestry)
  const { setDetails: setAlertDetails } = useAlertContext()

  const clearAncestors = () => {
    setAncestors(blankAncestry)
  }

  const addLeafDescendant = (descedant: Ancestor, type: HierarchyTypes) => {
    setAncestors({ ...ancestors, [type]: descedant })
  }

  const directHierarchyChange = (uuid: UUID, type: HierarchyTypes) => {
    if (ancestors[type]?.guid === uuid) {
      //drop "lower" descendants
      const ancestry = [
        HierarchyTypes.COMBO,
        HierarchyTypes.COVERAGE,
        HierarchyTypes.POLICY,
        HierarchyTypes.UNITSTAT,
        HierarchyTypes.CLAIM,
        HierarchyTypes.RATING,
      ]
      const index = ancestry.indexOf(type)
      for (let i = index + 1; i < ancestry.length; i++) {
        ancestors[ancestry[i]] = null
      }
    } else {
      // THEN fenix should remove all nodes from the HC
      clearAncestors()
      // AND call the ancestors endpoint
      getAncestors(uuid, type)
        .then((response) => {
          setAncestors(response)
        })
        .catch(() => {
          setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
        })
    }
  }

  return (
    <HierarchyContext.Provider
      value={{
        ancestors,
        addLeafDescendant,
        directHierarchyChange,
        clearAncestors,
      }}
    >
      {children}
    </HierarchyContext.Provider>
  )
}

const useHierarchy = (): HierarchyContextProps => {
  const context = useContext(HierarchyContext)

  if (!context) {
    throw new Error('useHierarchy must be used within a HierarchyProvider')
  }

  return context
}

export { HierarchyProvider, useHierarchy }
