export const NoAlphaCharacters = (input: string) => {
  return /^[0-9]*$/.test(input)
}

export const NoSpecialCharacters = (input: string) => {
  return /^[a-zA-Z0-9]*$/.test(input)
}

/// This function will allow only alpha characters, spaces and & symbol
export const NoSpecialCharactersWithExceptions = (input: string) => {
  return /^[a-zA-Z0-9&\s]*$/.test(input)
}
