import { Grid } from '@mui/material'
import { ErrorDTO, ErrorTrackingTable, ErrorTrackingTableColumns, FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import { ErrorResponseModel } from '../../models/PolicyDetails/PolicyDetailsErrorModel'

export interface TransactionCardProps {
  error: ErrorResponseModel
  isLoading: boolean
  openAcceptModal: (errors: string[]) => void
  openRejectModal: (errors: string[]) => void
  openAttachments: (
    attachments: {
      name: string
      path: string
    }[]
  ) => void
}

export const TransactionCard = ({
  error,
  isLoading,
  openAcceptModal,
  openRejectModal,
  openAttachments,
}: TransactionCardProps) => {
  return (
    <PhoenixBaseCard
      variantType="Secondary"
      contentPadded
      collapsible
      cardTitle={`Txn Code ${error.transactionCode} - ${error.date}`}
    >
      <Grid container padding={'8px'}>
        <Grid item xs={3}>
          <FieldGroup label="Notice Date" value={error.noticeDate}></FieldGroup>
        </Grid>
        <Grid item xs={3}>
          <FieldGroup label="Fine Due Date" value={error.fineDueDate}></FieldGroup>
        </Grid>
        <Grid item xs={3}>
          <FieldGroup label="Fine Status" value={error.fineStatus}></FieldGroup>
        </Grid>
        <Grid item xs={3}>
          <FieldGroup label="Fine Status Date" value={error.fineStatusDate}></FieldGroup>
        </Grid>
      </Grid>
      <ErrorTrackingTable
        columns={ErrorTrackingTableColumns}
        data={error.errors as ErrorDTO[]}
        isLoading={isLoading}
        acceptFunc={(rows) => openAcceptModal(rows)}
        rejectFunc={(rows) => openRejectModal(rows)}
        attachmentsFunc={(rows) => openAttachments(rows)}
      />
    </PhoenixBaseCard>
  )
}
