import { Folder } from '@mui/icons-material'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Grid, Skeleton, Stack } from '@mui/material'
import { FieldGroup, PhoenixLink as Link, PhoenixBaseCard, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { PolicyDetailsResponseModel } from '../../../models/PolicyDetails/PolicyDetailsResponseModel'

interface PolicyInfoGutterCardProps {
  policy: PolicyDetailsResponseModel
  guid: UUID
  isLoading: boolean
}

const PolicyInfoGutterCard = ({ policy, guid, isLoading }: PolicyInfoGutterCardProps) => {
  const loadingContent = (
    <Grid sx={{ margin: '16px' }} container direction={'column'} data-testid="loading">
      <Grid item>
        <Skeleton width={'35%'}>
          <StatusBadge description="accepted" />
        </Skeleton>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
    </Grid>
  )
  const loadedContent = (
    <Stack sx={{ margin: '16px' }} data-testid="loaded">
      <Box width={'fit-content'} marginBottom={'8px'}>
        <StatusBadge description={policy.summary.status} />
      </Box>
      <FieldGroup label="Carrier ID" value={policy.summary.carrierId} icon={<BeachAccessIcon />} />
      <FieldGroup
        label="Policy Number"
        value={<Link to={`/policies/${guid}`} value={policy.summary.policyNumber} />}
        icon={<Folder />}
      />
      <FieldGroup label="Policy Effective Date" value={policy.summary.effectiveDate} icon={<CalendarTodayIcon />} />
      <FieldGroup label="Policy Expiration Date" value={policy.summary.expirationDate} icon={<CalendarTodayIcon />} />
      <FieldGroup
        label="State Effective Date"
        value={policy.information.stateEffectiveDate}
        icon={<CalendarTodayIcon />}
      />
    </Stack>
  )
  return (
    <Box sx={{ marginTop: '16px' }}>
      <PhoenixBaseCard cardTitle="Policy Information">{isLoading ? loadingContent : loadedContent}</PhoenixBaseCard>
    </Box>
  )
}

export default PolicyInfoGutterCard
