import { Box, Drawer } from '@mui/material'
import React from 'react'
import BottomLogo from '../../media/NYCIRBLogo.png'
import DrawerTabs from './styled/DrawerTabs'

interface SideDrawerProps {
  open: boolean
}

const SideDrawer = ({ open }: SideDrawerProps) => {
  return (
    <Drawer
      disableEnforceFocus
      disableScrollLock
      open={open}
      sx={{
        maxWidth: '80px',
        minHeight: '836px',
      }}
      hideBackdrop
    >
      <DrawerTabs />
      <Box sx={{ p: 2, mt: 'auto' }}>
        <img
          src={BottomLogo}
          alt="nycirbLogo"
          style={{
            width: '61px',
            height: '17px',
            padding: '0px',
          }}
        />
      </Box>
    </Drawer>
  )
}
export default SideDrawer
