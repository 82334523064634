import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'
import { PolicyDetailsDataContext, PolicyDetailsDataContextProps } from '../../contexts/PolicyDataProvider'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

const PolicyErrorStatusBanner = () => {
  // LayoutWithSideRail has a PolicyDetailsDataProvider that wraps the mainContent
  // Need to have this useContext down here to share same data as PolicyDetailsContent
  const data = useContext(PolicyDetailsDataContext as React.Context<PolicyDetailsDataContextProps>)
  const { outstandingErrors } = data.policySummaryData.summary

  return outstandingErrors ? (
    <Box pb={1}>
      <Alert key={'alert'} variant="filled" icon={<InfoOutlined />} severity={AlertSeverity.INFO}>
        <Typography>This policy is being tracked for errors</Typography>
      </Alert>
    </Box>
  ) : null
}

export default PolicyErrorStatusBanner
