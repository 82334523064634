import { UUID } from 'crypto'
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { BlankCoverage, ComboCoverageResponse, CoverageInfoModel, CoverageModel } from '../models/CoverageGroupModels'
import { getCoverageDataByCoverageId } from '../services/coverageService'
import { useAlertContext } from './AlertContext'

interface CoverageDataContextProps {
  primaryCoverage: CoverageModel
  isLoadingCoverageGroupData: boolean
  relatedCoverages: CoverageInfoModel[]
  selectedCoverageGroupID: string
  populateCoverageData: (comboId: UUID, primaryCoverageId?: UUID) => void
  coverageDataRetrieved: boolean
  errorRetrievingCoverageData: boolean
}

export const CoverageDataContext = createContext<CoverageDataContextProps | undefined>(undefined)

interface CoverageDataProviderProps {
  children: ReactNode
}

const CoverageDataProvider: React.FC<CoverageDataProviderProps> = ({ children }) => {
  const [isLoadingCoverageGroupData, setIsLoadingCoverageGroupData] = useState(true)
  const [primaryCoverage, setPrimaryCoverage] = useState(BlankCoverage)
  const [relatedCoverages, setRelatedCoverages] = useState(Array<CoverageInfoModel>)
  const [selectedCoverageGroupID, setSelectedCoverageGroupID] = useState('')
  const [coverageDataRetrieved, setCoverageDataRetrieved] = useState<boolean>(false)
  const [errorRetrievingCoverageData, setErrorRetrievingCoverageData] = useState<boolean>(false)
  const { setDetails: setAlertDetails } = useAlertContext()

  /* istanbul ignore next */
  const populateCoverageData = (comboId: UUID, primaryCoverageId?: UUID) => {
    if (!comboId) {
      setIsLoadingCoverageGroupData(false)
      return
    }
    if (coverageDataRetrieved && primaryCoverageId && primaryCoverage.coverageInfo?.guid == primaryCoverageId) {
      setIsLoadingCoverageGroupData(false)
      return
    }
    if (primaryCoverageId == BlankCoverage.coverageInfo.guid) {
      primaryCoverageId = undefined
    }

    getCoverageDataByCoverageId(comboId, primaryCoverageId)
      .then((response: ComboCoverageResponse) => {
        if (!response.primary) {
          throw Error('response values undefined')
        }
        setPrimaryCoverage(response.primary)
        setRelatedCoverages(response.related)
        setSelectedCoverageGroupID(response.primary.coverageInfo.id)
        setCoverageDataRetrieved(true)
      })
      .catch(() => {
        setAlertDetails({
          message: 'An error occurred while retrieving coverage group data',
          severity: AlertSeverity.ERROR,
        })
        setErrorRetrievingCoverageData(true)
      })
      .finally(() => {
        setIsLoadingCoverageGroupData(false)
      })
  }

  return (
    <CoverageDataContext.Provider
      value={{
        primaryCoverage,
        isLoadingCoverageGroupData,
        relatedCoverages,
        selectedCoverageGroupID,
        populateCoverageData,
        coverageDataRetrieved,
        errorRetrievingCoverageData,
      }}
    >
      {children}
    </CoverageDataContext.Provider>
  )
}

const useCoverageData = (): CoverageDataContextProps => {
  const context = useContext(CoverageDataContext)

  if (!context) {
    throw new Error('useCoverageData must be used within a CoverageDataProvider')
  }

  return context
}

export { CoverageDataProvider, useCoverageData }
