import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { ExposureDTO } from '../../../models/SplitsModel'

// updateType: string,
// exposureACTCode: string,
// classCode: string,
// exposureAmount: number,
// manualRate: number,
// premiumRate: number,
// previous: ExposureDTO[]

/* eslint react/prop-types: 0 */ /* istanbul ignore next */
export const ExposureDtoColumns: MRT_ColumnDef<ExposureDTO>[] = [
  {
    accessorKey: 'updateType',
    header: 'Update Type',
  },
  {
    accessorKey: 'exposureACTCode',
    header: 'Expo ACT',
  },
  {
    accessorKey: 'classCode',
    header: 'Class',
  },
  {
    accessorKey: 'exposureAmount',
    header: 'Exposure Amount',
    Cell: ({ cell }) => {
      return (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
            cell.getValue<number>()
          )}
        </>
      )
    },
  },
  {
    accessorKey: 'manualRate',
    header: 'Manual Rate',
  },
  {
    accessorKey: 'premiumRate',
    header: 'Premium Amount',
    Cell: ({ cell }) => {
      return (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
            cell.getValue<number>()
          )}
        </>
      )
    },
  },
]

export default ExposureDtoColumns
