import { PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import React, { useEffect } from 'react'
import PolicyDetailsCancellationsReintstatementsModel from '../../models/PolicyDetails/PolicyDetailsCancellationsReintstatementsModel'
import { getPolicyCancellationsReinstatements } from '../../services/policyService'
import { PolicyDetailsCancellationsColumns } from './PolicyDetailsCancellationsTable.tsx/PolicyDetailsCancellationsColumns'

interface PolicyCancellationsReinstatementsProps {
  uuid: string
}
export const PolicyCancellationsReinstatements = ({ uuid }: PolicyCancellationsReinstatementsProps) => {
  const [isLoadingData, setIsLoadingData] = React.useState(false)
  const [data, setData] = React.useState([] as PolicyDetailsCancellationsReintstatementsModel[])
  const [error, setError] = React.useState(false)

  useEffect(() => {
    if (error) {
      setIsLoadingData(false)
      console.log('error')
    }
  }, [error])

  /* istanbul ignore next */
  const getPolicyCancellationsData = async () => {
    try {
      setIsLoadingData(true)
      setData([] as PolicyDetailsCancellationsReintstatementsModel[])
      const responseData = await getPolicyCancellationsReinstatements(uuid)

      if (responseData) {
        setData(responseData)
      }
      setIsLoadingData(false)
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }

  /* istanbul ignore next */
  const handleOnExpand = () => {
    if (!data || data.length === 0) {
      getPolicyCancellationsData()
    }
  }

  return (
    <PhoenixBaseCard
      cardTitle={'Cancellation/Reinstatements'}
      collapsible={true}
      onExpand={handleOnExpand}
      isLoading={isLoadingData}
    >
      {!isLoadingData && (
        <PhoenixBaseTable
          columns={PolicyDetailsCancellationsColumns}
          data={data ?? ([] as PolicyDetailsCancellationsReintstatementsModel[])}
          isLoading={isLoadingData}
        />
      )}
    </PhoenixBaseCard>
  )
}

export default PolicyCancellationsReinstatements
