import axios, { AxiosError } from 'axios'
import { getSilentToken } from './auth'

export interface ApiResponse<T> {
  data: T
  status: number
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

const handleApiError = (error: AxiosError) => {
  if (axios.isCancel(error)) {
    console.log('Axios Error: ', error)
  } else {
    console.log('API Error: ', error)
  }
  throw error
}

api.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = await getSilentToken()
      if (accessToken) {
        config.headers.Authorization = `${accessToken}`
      }
    } catch (error) {
      console.error('Error setting authorization token:', error)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => handleApiError(error)
)

export default api
