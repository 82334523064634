import { Box, Link } from '@mui/material'
import { KebabNavigationMenu, PhoenixBaseCard, PhoenixBaseTable, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertSeverity } from '../../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../../contexts/AlertContext'
import UnitStatModel from '../../models/UnitStatModel'
import { getPolicyUnitStats } from '../../services/policyService'

const UnitStatReportsTable = ({ policyID }: { policyID: string }) => {
  const [isLoadingData, setIsLoadingData] = useState(false)

  const [data, setData] = React.useState([] as UnitStatModel[])
  const { setDetails: setAlertDetails } = useAlertContext()

  const navigate = useNavigate()

  useEffect(() => {
    setData([] as UnitStatModel[])
  }, [])

  const getPolicyUnitStatsData = async (policyID: string) => {
    try {
      setIsLoadingData(true)
      setData([] as UnitStatModel[])
      const responseData = await getPolicyUnitStats(policyID)

      if (responseData) {
        setData(responseData)
      }
      setIsLoadingData(false)
      // eslint-disable-next-line
    } catch (exception) {
      setIsLoadingData(false)
      setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
    }
  }
  const handleFirstOpen = () => {
    if (data.length === 0) {
      getPolicyUnitStatsData(policyID)
    }
  }

  // ============================= START COLUMN DEF=============================
  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const columns = useMemo<MRT_ColumnDef<UnitStatModel>[]>(
    () => [
      {
        accessorKey: 'receivedDate',
        header: 'RECEIVED DATE',
        Cell: ({ cell, row }) => (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box paddingRight={'8px'}>
                <KebabNavigationMenu
                  id={`UnitListNavButton-${row.index}`}
                  menuItems={[
                    {
                      label: 'View Unit Stat Details',
                      id: `unitStatNavMenuItem-1`,
                      to: `/unitstats/${row.original.unitStatId}`,
                    },
                  ]}
                />
              </Box>
              {/* eslint-disable-next-line */}
              <>{cell.getValue()}</>
            </Box>
          </>
        ),
        size: 10,
      },
      {
        accessorKey: 'status',
        header: 'STATUS',
        Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>() ?? '----'} />,
        size: 10,
      },

      {
        accessorKey: 'reportLevel',
        header: 'REPORT LEVEL',
        Cell: ({ cell, row }) => (
          <Link
            id={`ReportLink-${row.index}`}
            onClick={() => {
              navigate(`/unitstats/${row.original.unitStatId}`)
            }}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            {/* eslint-disable-next-line */}
            <>{cell.getValue() ?? '----'}</>
          </Link>
        ),
      },
      {
        accessorKey: 'correctionSeq',
        header: 'CORRECTION SEQ',
        Cell: ({ cell }) => <>{cell.getValue() ?? '----'}</>,
        size: 10,
      },

      {
        accessorKey: 'correctionType',
        header: 'CORRECTION TYPE',
        Cell: ({ cell }) => <>{cell.getValue() ?? '----'}</>,
        size: 10,
      },
    ],
    // eslint-disable-next-line
    []
  )
  // ============================= END COLUMN DEF ==============================

  return (
    <PhoenixBaseCard cardTitle={'Unit Stat Reports'} isLoading={isLoadingData} collapsible onExpand={handleFirstOpen}>
      <PhoenixBaseTable columns={columns} data={data ?? ([] as UnitStatModel[])} isLoading={isLoadingData} />
    </PhoenixBaseCard>
  )
}
export default UnitStatReportsTable
