import { Chip, Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import PolicyDetailsInformationModel from '../../models/PolicyDetails/PolicyDetailsInformationModel'

interface PolicyDetailsInformationCardProps {
  isLoading?: boolean
  data: PolicyDetailsInformationModel
}

export const PolicyDetailsInformationCard = ({ isLoading, data }: PolicyDetailsInformationCardProps) => {
  const gridItemHeight = '64px'
  const itemBreakPointVars = {
    xs: 12,
    sm: 4,
    md: 4,
  }

  return (
    <PhoenixBaseCard
      variantType="Secondary"
      isLoading={isLoading}
      cardTitle="Policy Information"
      contentPadded
      defaultWidth={6}
    >
      <Grid container spacing={2} padding={'8px'} rowGap={'16px'}>
        <Grid item sm={12} height={gridItemHeight} display={'flex'} alignItems={'center'} gap={'16px'}>
          {<Chip label={'Multi-State'} />}
          {<Chip label={'Wrap-Up'} />}
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Policy Type'} value={data?.policyType} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Employee Leasing'} value={data?.employeeLeasingType} multiLineWrap />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'Policy Term Code'} value={data?.policyTermCode} />
        </Grid>
        <Grid item sm={itemBreakPointVars.sm} height={gridItemHeight} padding={'8px'}>
          <FieldGroup label={'State Effective Date'} value={data?.stateEffectiveDate} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
export default PolicyDetailsInformationCard
