import { DataFieldBlankValue, PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useMemo } from 'react'
import { ExposureModelTable } from '../../../models/ExposureModelTable'

interface ExposureTableProps {
  searchData: ExposureModelTable[]
  isLoading: boolean
}

const ExposureTable = ({ searchData, isLoading }: ExposureTableProps) => {
  /* eslint react/prop-types: 0 */
  const columns = useMemo<MRT_ColumnDef<ExposureModelTable>[]>(
    () => [
      {
        accessorKey: 'periodEffectiveDate',
        header: 'PERIOD EFFECTIVE',
      },
      {
        accessorKey: 'effectiveDate',
        header: 'EFFECTIVE DATE',
      },
      {
        accessorKey: 'expirationDate',
        header: 'EXPIRATION DATE',
      },
      {
        accessorKey: 'class',
        header: 'CLASS',
      },
      {
        accessorKey: 'classDescription',
        header: 'CLASS DESCRIPTION',
        size: 250,
      },
      {
        accessorKey: 'act',
        header: 'ACT',
        Cell: ({ cell }) => <>{cell.getValue() ?? <DataFieldBlankValue />}</>,
      },
      {
        accessorKey: 'exposure',
        header: 'EXPOSURE',
        Cell: ({ cell }) => <>{cell.getValue() ?? <DataFieldBlankValue />}</>,
      },

      {
        accessorKey: 'rate',
        header: 'RATE',
      },
      {
        accessorKey: 'premium',
        header: 'PREMIUM',
        Cell: ({ cell, row }) => (
          <span data-testid={`premium-${row.index}`}>
            {cell.getValue() ? (
              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
                cell.getValue() as number
              )
            ) : (
              <DataFieldBlankValue />
            )}
          </span>
        ),
      },
    ],
    []
  )

  return <PhoenixBaseTable data={searchData} isLoading={isLoading} columns={columns} />
}

export default ExposureTable
