import { InfoOutlined } from '@mui/icons-material'
import { AlertTitle, Box, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import React from 'react'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

interface AdditionalInformationBannerProps {
  additionalInformation: string[]
}

const RatingAdditionalInformationBanner = ({ additionalInformation }: AdditionalInformationBannerProps) => {
  if (!additionalInformation || additionalInformation.length === 0) return null

  return (
    <Box p={0.5} mb={1}>
      <Alert icon={<InfoOutlined />} severity={AlertSeverity.INFO}>
        <AlertTitle sx={{ paddingTop: '2px' }}>Rating Held</AlertTitle>
        <ul style={{ paddingLeft: 24, marginTop: 4, marginBottom: 4 }}>
          {additionalInformation.map((info) => (
            <li key={info} style={{ paddingLeft: 4 }}>
              <Typography variant={'body2'}>{info}</Typography>
            </li>
          ))}
        </ul>
      </Alert>
    </Box>
  )
}

export default RatingAdditionalInformationBanner
