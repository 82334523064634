import { Chip, Grid } from '@mui/material'
import { FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import React from 'react'
import UnitStatPolicyConditionsModel from '../../models/UnitStatPolicyConditionsModel'

export interface PolicyConditionsProps {
  unitStatConditionsData: UnitStatPolicyConditionsModel
  isLoadingData: boolean
}

const PolicyConditions = ({ unitStatConditionsData, isLoadingData }: PolicyConditionsProps) => {
  return (
    <>
      <PhoenixBaseCard
        data-testid={'policy-conditions-card'}
        cardTitle={'Policy Conditions'}
        variantType="Secondary"
        isLoading={isLoadingData}
        collapsible={true}
      >
        {!unitStatConditionsData.cancelledMidterm &&
        (unitStatConditionsData.estimatedAuditCode === 'N' || !unitStatConditionsData.estimatedAuditCode) &&
        !unitStatConditionsData.interstatePolicy &&
        !unitStatConditionsData.mcoIndicator &&
        !unitStatConditionsData.multiStatePolicy &&
        !unitStatConditionsData.retroPolicy &&
        !unitStatConditionsData.threeYearFixed ? (
          <NoResultsFound />
        ) : (
          <Grid container sx={{ marginTop: '8px' }}>
            {unitStatConditionsData.multiStatePolicy && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup value={<Chip sx={{ marginTop: '4px' }} label={'Multi-state'} />} label={''} />
              </Grid>
            )}

            {unitStatConditionsData.threeYearFixed && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup value={<Chip sx={{ marginTop: '4px' }} label={'3-year Fixed'} />} label={''} />
              </Grid>
            )}

            {unitStatConditionsData.mcoIndicator && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup value={<Chip sx={{ marginTop: '4px' }} label={'MCO'} />} label={''} />
              </Grid>
            )}

            {unitStatConditionsData.retroPolicy && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup value={<Chip sx={{ marginTop: '4px' }} label={'Retro Policy'} />} label={''} />
              </Grid>
            )}

            {unitStatConditionsData.cancelledMidterm && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup value={<Chip sx={{ marginTop: '4px' }} label={'Canceled Mid-Term'} />} label={''} />
              </Grid>
            )}

            {unitStatConditionsData.interstatePolicy && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup value={<Chip sx={{ marginTop: '4px' }} label={'Interstate Rating'} />} label={''} />
              </Grid>
            )}

            {unitStatConditionsData.estimatedAuditCode && unitStatConditionsData.estimatedAuditCode !== 'N' && (
              <Grid item sx={{ marginLeft: '8px' }}>
                <FieldGroup
                  value={
                    <Chip
                      sx={{ marginTop: '4px' }}
                      label={
                        unitStatConditionsData.estimatedAuditCode === 'Y' ? 'Estimated Audit' : 'Uncooperative Audit'
                      }
                    />
                  }
                  label={''}
                />
              </Grid>
            )}
          </Grid>
        )}
      </PhoenixBaseCard>
    </>
  )
}

export default PolicyConditions
