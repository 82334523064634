export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_REGISTRATION_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_REDIRECT_URL as string,
    authority: 'https://login.microsoftonline.com/645fd283-40d4-45ca-8e2b-e8e5913b0f7b',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}
