export interface UnitStatDetailsModel {
  status: string
  receivedDate: string
  acceptedDate: string
  reportLevel: string // D2, e.g. 05
  correctionSequence: string //D2 e.g. 01
  correctionType: string
  outstandingErrors: boolean
}

export const blankUnitStatDetailsModel: UnitStatDetailsModel = {
  status: '',
  receivedDate: '',
  acceptedDate: '',
  reportLevel: '',
  correctionSequence: '',
  correctionType: '',
  outstandingErrors: false,
}

export default UnitStatDetailsModel
