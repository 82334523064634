import { AccountCircle } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'

interface TopMenuAccountMenuProps {
  handleMenu: React.MouseEventHandler<HTMLButtonElement> | undefined
  anchor: HTMLElement | null
  handleClose: () => void
  handleSignOutClicked: () => void
}

const TopMenuAccountMenu = ({ handleMenu, anchor, handleClose, handleSignOutClicked }: TopMenuAccountMenuProps) => {
  return (
    <>
      <IconButton onClick={handleMenu} color="inherit">
        <AccountCircle sx={{ width: '40px', height: '40px' }} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <MenuItem id="signOutButton" onClick={handleSignOutClicked}>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  )
}
export default TopMenuAccountMenu
