import AddressDTOModel, { EmptyAddressDTOModel } from '../AddressDTOModel'

export default interface PolicyDetailsProducerModel {
  name: string
  address: AddressDTOModel
  email: string
}

export const EmptyProducerModel = {
  name: '',
  address: EmptyAddressDTOModel,
  email: '',
}
