import { PhoenixBaseCard } from 'componix'
import React, { useEffect } from 'react'
import PolicyDetailsEndorsementsModel from '../../models/PolicyDetails/PolicyDetailsEndorsementsModel'
import { getPolicyEndorsements } from '../../services/policyService'
import PolicyEndorsementsTable from './PolicyEndorsementsTable'

interface PolicyEndorsementsProps {
  uuid: string
}

export const PolicyEndorsementsContent = ({ uuid }: PolicyEndorsementsProps) => {
  const [isLoadingData, setIsLoadingData] = React.useState(false)
  const [data, setData] = React.useState([] as PolicyDetailsEndorsementsModel[])
  const [error, setError] = React.useState(false)

  useEffect(() => {
    setData([] as PolicyDetailsEndorsementsModel[])
  }, [])

  useEffect(() => {
    if (error) {
      setIsLoadingData(false)
      console.log('error')
    }
  }, [error])

  const getPolicyEndorsementsData = async () => {
    try {
      setIsLoadingData(true)
      setData([] as PolicyDetailsEndorsementsModel[])
      const responseData = await getPolicyEndorsements(uuid)

      if (responseData) {
        setData(responseData)
      }
      setIsLoadingData(false)
      // eslint-disable-next-line
    } catch (exception) {
      setError(true)
    }
  }

  const handleOnExpand = () => {
    if (!data || data.length === 0) {
      getPolicyEndorsementsData()
    }
  }

  return (
    <PhoenixBaseCard cardTitle={'Endorsements'} collapsible={true} onExpand={handleOnExpand} isLoading={isLoadingData}>
      {!isLoadingData && <PolicyEndorsementsTable data={data} isLoading={isLoadingData} />}
    </PhoenixBaseCard>
  )
}

export default PolicyEndorsementsContent
