import { DataFieldBlankValue } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { EmployerLocations } from '../../models/PolicyDetails/PolicyDetailsEmployerLocations'

/* eslint react/prop-types: 0 */
export const PolicyEmployerLocationsColumns: MRT_ColumnDef<EmployerLocations>[] = [
  {
    accessorKey: 'address.street',
    header: 'Address',
  },
  {
    accessorKey: 'address.city',
    header: 'City',
  },
  {
    accessorKey: 'address.state',
    header: 'State',
  },
  {
    accessorKey: 'address.zipCode',
    header: 'Zip Code',
    Cell: ({ cell }) => <>{cell.getValue<string>()?.substring(0, 5) ?? <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective',
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration',
  },
  {
    accessorKey: 'receivedDate',
    header: 'Received',
  },
]

export default PolicyEmployerLocationsColumns
