import AddressDTOModel, { EmptyAddressDTOModel } from './AddressDTOModel'

export interface ComboCoverageResponse {
  primary: CoverageModel
  related: CoverageInfoModel[]
}

export interface CoverageModel {
  coverageInfo: CoverageInfoModel
  address: AddressDTOModel
  fein: string
}

export interface CoverageInfoModel {
  id: string
  guid: string
  name: string
  controlling: boolean
}

export const BlankCoverage: CoverageModel = {
  coverageInfo: {
    id: '',
    guid: '',
    name: '',
    controlling: false,
  },
  address: EmptyAddressDTOModel,
  fein: '',
}
