import { useState } from 'react'
import { AlertSeverity } from '../../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../../contexts/AlertContext'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import { updatePolicyErrors } from '../../services/policyService'
import { useBoolean } from '../useBoolean'

interface PolicyErrorModal {
  isModalOpen: boolean
  isCallLoading: boolean
  openModal: (errors: string[]) => void
  closeModal: () => void
  acceptErrors: (policyId: string) => void
  rejectErrors: (policyId: string, responseText: string) => void
  currentErrorIDs: string[]
}

const usePolicyErrorModal = (refetchErrors: () => Promise<void>): PolicyErrorModal => {
  const { setDetails } = useAlertContext()
  const [currentErrorIDs, setCurrentErrorIDs] = useState<string[]>([])
  const { value, onTrue, onFalse } = useBoolean()
  const { value: isCallLoading, onTrue: setIsCallLoadingTrue, onFalse: setIsCallLoadingFalse } = useBoolean()

  const openModal = (errors: string[]) => {
    onTrue()
    setCurrentErrorIDs(errors)
  }

  const closeModal = () => {
    onFalse()
    setCurrentErrorIDs([])
  }

  const acceptErrors = async (policyID: string) => {
    closeModal()
    const request: UpdateErrorsModel = { editIDs: currentErrorIDs, userAccepted: true }
    try {
      setIsCallLoadingTrue()
      await updatePolicyErrors(policyID, request)
      setDetails({ message: 'Transaction Edit(s) accepted', severity: AlertSeverity.SUCCESS })
      await refetchErrors()
      // eslint-disable-next-line
    } catch (exception) {
      setDetails({ message: 'Update was unsuccessful. Please try again.', severity: AlertSeverity.ERROR })
    }
    setIsCallLoadingFalse()
  }

  const rejectErrors = async (policyID: string, responseText: string) => {
    closeModal()
    const request: UpdateErrorsModel = {
      editIDs: currentErrorIDs,
      userAccepted: false,
      responseText,
    }
    try {
      setIsCallLoadingTrue()
      await updatePolicyErrors(policyID, request)
      setDetails({ message: 'Transaction Edits(s) rejected and response(s) sent', severity: AlertSeverity.ERROR })
      // eslint-disable-next-line
    } catch (exception) {
      setDetails({ message: 'Update was unsuccessful. Please try again.', severity: AlertSeverity.ERROR })
    }
    setIsCallLoadingFalse()
  }

  return {
    isModalOpen: value,
    isCallLoading,
    openModal,
    closeModal,
    acceptErrors,
    rejectErrors,
    currentErrorIDs, // exported for testing purposes
  }
}

export default usePolicyErrorModal
