import { TableWithSubrowsProps } from 'componix'
import {
  BreadcrumbBar,
  DataFieldBlankValue,
  PhoenixBaseCard,
  PhoenixLink,
  StatusBadge,
  TableWithSubrows,
} from 'componix'
import { UUID } from 'crypto'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import { useAlertContext } from '../contexts/AlertContext'
import { useCoverageData } from '../contexts/CoverageDataContext'
import { useHierarchy } from '../contexts/HierarchyContext'
import { useNavbar } from '../contexts/NavbarContext'
import { Ancestor } from '../models/AncestorResponse'
import { RatingHistoryDTO } from '../models/RatingHistoryDTO'
import { getComboInfo, getRatingHistory } from '../services/comboService'
import { booleanToYN } from '../utils/booleanToYN'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const Combo: React.FC = () => {
  const { comboId } = useParams()
  const { addLeafDescendant, clearAncestors, ancestors } = useHierarchy()
  const { marginLeft } = useNavbar()
  const [ratingHistoryData, setRatingHistoryData] = useState([] as RatingHistoryDTO[])
  const [isLoading, setIsLoading] = useState(false)
  const { populateCoverageData, primaryCoverage } = useCoverageData()

  const [error, setError] = useState(false)
  const { setDetails: setAlertDetails } = useAlertContext()

  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const columns: MRT_ColumnDef<RatingHistoryDTO>[] = [
    { accessorKey: 'effectiveDate', size: 150, header: 'RATING EFFECTIVE DATE' },
    { accessorKey: 'revisionNumber', header: 'REVISION #', size: 20 },
    {
      accessorKey: 'hasInterstateId',
      header: 'INTERSTATE ID INDICATOR',
      Cell: ({ cell }) => booleanToYN(cell.getValue<boolean>()),
    },
    {
      accessorKey: 'modFactor',
      header: 'MOD FACTOR',
      Cell: ({ row, cell }) =>
        cell.getValue() ? (
          <PhoenixLink to={`/ratings/${row.original.ratingID}`} value={row.original.modFactor.toString()} />
        ) : (
          <DataFieldBlankValue />
        ),
    },
    {
      accessorKey: 'transitionalModCap',
      header: 'TRANSITIONAL MOD CAP',
      Cell: ({ cell }) => booleanToYN(cell.getValue<boolean>()),
    },
    { accessorKey: 'result', header: 'RESULT' },
    {
      accessorKey: 'status',
      header: 'STATUS',
      Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
    },
    { accessorKey: 'statusDate', header: 'STATUS DATE' },
    { accessorKey: 'statusBy', header: 'STATUS BY' },
  ]
  const navMenuOptions: TableWithSubrowsProps<RatingHistoryDTO>['kebabMenuOptions'] = [
    {
      label: 'View Rating Details',
      to: ({ row }) => `/ratings/${row.original.ratingID}`,
    },
  ]

  /* istanbul ignore next */
  useEffect(() => {
    if (comboId != undefined) {
      setIsLoading(true)
      clearAncestors()
      getComboInfo(comboId).then((response) => {
        addLeafDescendant({ identifier: response.comboId.toString(), guid: comboId as UUID }, HierarchyTypes.COMBO)
      })
      populateCoverageData(comboId as UUID)
      getRatingHistory(comboId as string)
        .then((data) => {
          setRatingHistoryData(data)
        })
        .catch((error) => {
          setError(true)
          setAlertDetails({ message: error, severity: AlertSeverity.ERROR })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line
  }, [comboId])
  /* istanbul ignore next */
  useEffect(() => {
    if (error) {
      setIsLoading(false)
      setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
    }
  }, [error, setAlertDetails])

  return (
    <div style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestors.combo?.identifier as string}`,
            to: `/combos/${ancestors.combo?.guid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <div>
            <PhoenixBaseCard cardTitle={'Rating History'}>
              <TableWithSubrows
                columns={columns}
                data={ratingHistoryData}
                isLoading={isLoading}
                kebabColumnHeader=""
                kebabMenuOptions={navMenuOptions}
              />
            </PhoenixBaseCard>
          </div>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key={''}
            title="Combo Group"
            combo={ancestors?.combo as Ancestor}
            coverage={{
              guid: primaryCoverage?.coverageInfo.guid as UUID,
              identifier: primaryCoverage?.coverageInfo.id,
            }}
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}

export default Combo
