import { Box, Link } from '@mui/material'
import { BreadcrumbBar, KebabNavigationMenu, PhoenixBaseCard, PhoenixBaseTable, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { ExperienceRating } from '../components/ExperienceRating'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import RelateCoverageIDsCard from '../components/RelatedCoverageIDs'
import { useAlertContext } from '../contexts/AlertContext'
import { useCoverageData } from '../contexts/CoverageDataContext'
import { useHierarchy } from '../contexts/HierarchyContext'
import { useNavbar } from '../contexts/NavbarContext'
import { Ancestor } from '../models/AncestorResponse'
import { Policy } from '../models/Policy'
import { getComboDataByCoverageId } from '../services/coverageService'
import { getPolicyListByCoverageId } from '../services/policyService'
import { HierarchyTypes } from '../utils/hierarchy.enum'

export const Employers = () => {
  const { coverageId } = useParams()
  const { directHierarchyChange, ancestors } = useHierarchy()

  const { marginLeft } = useNavbar()
  const [localCoverageID, setLocalCoverageID] = useState('')
  const [policyListData, setPolicyListData] = useState<Policy[]>([])
  const { populateCoverageData } = useCoverageData()

  const [isLoading, setIsLoading] = useState(true)

  const [error, setError] = useState(false)
  const { setDetails: setAlertDetails } = useAlertContext()

  // ============================= START COLUMN DEF=============================
  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const policyColumns = useMemo<MRT_ColumnDef<Policy>[]>(
    () => [
      {
        accessorKey: 'carrierId',
        header: 'CARRIER ID',
        size: 40,
        Cell: ({ cell, row }) => (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box paddingRight={'8px'}>
                <KebabNavigationMenu
                  id={`PolicyListNavButton-${row.index}`}
                  menuItems={[
                    {
                      label: 'View Policy Details',
                      id: `policyDetailsNavMenuItem-0`,
                      to: `/policies/${row.original.policyId}`,
                    },
                  ]}
                />
              </Box>
              {/* eslint-disable-next-line */}
              <>{cell.getValue<string>()}</>
            </Box>
          </>
        ),
      },
      {
        accessorKey: 'carrierName',
        header: 'CARRIER NAME',
        size: 150,
      },
      {
        accessorKey: 'policyNumber',
        header: 'POLICY NO',
        Cell: ({ cell, row }) => (
          <Link
            id={`PolicyNumberLink-${row.index}`}
            component={RouterLink}
            to={`/policies/${row.original.policyId}`}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {/* eslint-disable-next-line */}
            <>{cell.getValue()}</>
          </Link>
        ),
        size: 15,
      },
      {
        accessorKey: 'policyEffectiveDate',
        header: 'EFFECTIVE DATE',
        size: 15,
      },
      {
        accessorKey: 'policyExpirationDate',
        header: 'EXPIRATION DATE',
        size: 15,
      },
      {
        accessorKey: 'status',
        header: 'STATUS',
        Cell: ({ cell }) => (
          // eslint-disable-next-line
          <StatusBadge description={cell.getValue<string>()} />
        ),
        size: 15,
      },

      {
        accessorKey: 'statusDate',
        header: 'STATUS DATE',
        size: 15,
      },
      {
        accessorKey: 'issueDate',
        header: 'ISSUE DATE',
        size: 15,
      },
      {
        accessorKey: 'unitStatReport.dueDate',
        header: 'USR DUE DATE',
        size: 15,
        Cell: ({ row }) =>
          row.original.unitStatReport != null && row.original.unitStatReport.nextDue != null
            ? `${row.original.unitStatReport.nextDue} - ${row.original.unitStatReport.dueDate}`
            : '----',
      },
    ],
    []
  )
  // ============================= END COLUMN DEF ==============================

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      setAlertDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
    }
  }, [error, setAlertDetails])

  useEffect(() => {
    populateCoverageData(ancestors.combo?.guid as UUID, ancestors.coverage?.guid)
  }, [ancestors.combo, ancestors.coverage, populateCoverageData])
  /* istanbul ignore next */
  useEffect(() => {
    if (coverageId != null && coverageId != undefined && coverageId.length > 0) {
      getComboDataByCoverageId(coverageId as string)
        .then((response) => {
          if (response.comboGuid == null) {
            throw 'Error in retrieving combo ID'
          }
          directHierarchyChange(response.coverageGuid as UUID, HierarchyTypes.COVERAGE)
          setLocalCoverageID(response.coverageGuid as string)
        })
        .catch((e) => {
          setError(true)
          setAlertDetails({ message: e, severity: AlertSeverity.ERROR })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageId])

  useEffect(() => {
    if (localCoverageID != null && localCoverageID.length > 0) {
      getPolicyListByCoverageId(localCoverageID).then((response) => {
        setPolicyListData(response)
        setIsLoading(false)
      })
    }
  }, [localCoverageID])

  return (
    <div style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestors.combo?.identifier as string}`,
            to: `/combos/${ancestors.combo?.guid}`,
          },
          {
            label: `Coverage ${coverageId}`,
            to: `/coverages/${coverageId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <PhoenixBaseCard cardTitle={'Policies'}>
            <PhoenixBaseTable
              data-testid="mrtDataTable"
              data={policyListData}
              isLoading={isLoading}
              columns={policyColumns}
            />
          </PhoenixBaseCard>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key=""
            combo={ancestors.combo as Ancestor}
            coverage={{ identifier: coverageId, guid: localCoverageID } as Ancestor}
          />,
          <ExperienceRating comboId={ancestors.combo?.identifier} key="er" />,
          <RelateCoverageIDsCard isLoading={isLoading} key="" />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
