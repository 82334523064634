export default interface AddressDTOModel {
  street: string
  city: string
  state: string
  zipCode: string
}

export const EmptyAddressDTOModel: AddressDTOModel = {
  street: '',
  city: '',
  state: '',
  zipCode: '',
}
