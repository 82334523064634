import { DataFieldBlankValue, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { ContributingExperienceModel } from '../../models/ContributingExperienceModel'
import { booleanToYN } from '../../utils/booleanToYN'
import { formatMoney } from '../../utils/moneyFormatter'

/* istanbul ignore next */
/* eslint react/prop-types: 0 */
export const ContributingExperienceColumns: MRT_ColumnDef<ContributingExperienceModel>[] = [
  {
    accessorKey: 'coverageID',
    header: 'COVERAGE ID',
  },
  {
    accessorKey: 'carrierID',
    header: 'CARRIER ID',
  },
  {
    accessorKey: 'policyNumber',
    header: 'POLICY NO',
    Cell: ({ cell, row }) => (
      <div>
        {cell.getValue() ? (
          <PhoenixLink
            id={`Policy-${row.index}`}
            to={`/policies/${row.original.policyGuid}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </div>
    ),
  },
  {
    accessorKey: 'effectiveDate',
    header: 'EFFECTIVE DATE',
  },
  {
    accessorKey: 'reportLevel',
    header: 'REPORT LEVEL',
    Cell: ({ cell, row }) => (
      <div>
        {cell.getValue() ? (
          row.original.unitStatGuid ? (
            <PhoenixLink
              id={`UnitStat-${row.index}`}
              to={`/unitstats/${row.original.unitStatGuid}`}
              value={cell.getValue<string>()}
            />
          ) : (
            cell.getValue<string>()
          )
        ) : (
          <DataFieldBlankValue />
        )}
      </div>
    ),
  },
  {
    accessorKey: 'correctionSequence',
    header: 'CORRECTION SEQ',
  },
  {
    accessorKey: 'premium',
    header: 'PREMIUM',
    Cell: ({ cell }) => <div>{cell.getValue() ? formatMoney(cell.getValue<number>()) : <DataFieldBlankValue />}</div>,
  },

  {
    accessorKey: 'accepted',
    header: 'ACCEPTED',
    Cell: ({ cell }) => <div>{booleanToYN(cell.getValue<boolean>())}</div>,
  },
  {
    accessorKey: 'exclusionReason',
    header: 'EXCLUSION REASON',
  },
  {
    accessorKey: 'trigger',
    header: 'TRIGGER',
    Cell: ({ cell }) => <div>{booleanToYN(cell.getValue<boolean>())}</div>,
  },
  {
    accessorKey: 'overiddenby',
    header: 'OVERRIDDEN BY',
  },
]

export default ContributingExperienceColumns
