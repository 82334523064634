import { PhoenixBaseTable } from 'componix'
import React from 'react'
import { PolicyDetailsPremiumModel } from '../../../models/PolicyDetails/PolicyDetailsPremiumModel'
import PolicyPremiumsTableColumns from './PolicyPremiumsTableColumns'

interface PolicyPremiumTableProps {
  data: PolicyDetailsPremiumModel[]
  isLoading?: boolean
}

export const PolicyPremiumTable = ({ data, isLoading }: PolicyPremiumTableProps) => {
  return (
    <PhoenixBaseTable columns={PolicyPremiumsTableColumns} data={data} showPagination={false} isLoading={isLoading} />
  )
}

export default PolicyPremiumTable
