import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle, IconButton } from '@mui/material'
import React from 'react'

interface PhoenixModalHeaderProps {
  title: string
  isErrorColor: boolean
  handleClose: () => void
}

const PhoenixModalHeader = ({ title, isErrorColor, handleClose }: PhoenixModalHeaderProps) => {
  return (
    <>
      <DialogTitle
        variant="subtitle2"
        sx={{
          backgroundColor: (theme) => (isErrorColor ? theme.palette.error.main : '#d8e1ed'),
          color: (theme) => (isErrorColor ? theme.palette.primary.contrastText : theme.palette.text.primary),
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 4,
          color: (theme) => (isErrorColor ? theme.palette.primary.contrastText : theme.palette.text.primary),
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  )
}

export default PhoenixModalHeader
