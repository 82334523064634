import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Grid, Skeleton, Stack } from '@mui/material'
import { FieldGroup, PhoenixLink as Link, PhoenixBaseCard, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import UnitStatDetailsModel from '../../../models/UnitStatDetailsModel'

interface UnitStatInfoGutterCardProps {
  unitStat: UnitStatDetailsModel
  uuid: UUID
  isLoading: boolean
}

const UnitStatInfoGutterCard = ({ unitStat, uuid, isLoading }: UnitStatInfoGutterCardProps) => {
  const loadingContent = (
    <Grid sx={{ margin: '16px' }} container direction={'column'} data-testid="loading">
      <Grid item>
        <Skeleton width={'35%'}>
          <StatusBadge description="accepted" />
        </Skeleton>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton width={'50%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
          <Skeleton width={'50%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Skeleton width={'65%'}>
          <FieldGroup label="Skeleton" value="Skeleton" />
        </Skeleton>
      </Grid>

      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={30} height={30} sx={{ marginRight: '6px' }} />
          <Skeleton width={'65%'}>
            <FieldGroup label="Skeleton" value="Skeleton" />
          </Skeleton>
        </Box>
      </Grid>
    </Grid>
  )
  const loadedContent = (
    <Stack sx={{ margin: '16px' }} data-testid="loaded" spacing={1}>
      <Box width={'fit-content'}>
        <StatusBadge description={unitStat.status} />
      </Box>
      <Grid container columnGap={5}>
        <Grid item>
          <FieldGroup label="Report Level" value={<Link to={`/unitstats/${uuid}`} value={unitStat.reportLevel} />} />
        </Grid>
        <Grid item>
          <FieldGroup label="Correction Seq" value={unitStat.correctionSequence} />
        </Grid>
      </Grid>
      <FieldGroup label="Correction Type" value={unitStat.correctionType} />
      <FieldGroup label="Accepted Date" value={unitStat.acceptedDate} icon={<CalendarTodayIcon />} />
      <FieldGroup label="Received Date" value={unitStat.receivedDate} icon={<CalendarTodayIcon />} />
    </Stack>
  )
  return (
    <Box sx={{ marginTop: '16px' }}>
      <PhoenixBaseCard cardTitle="Unit Stat Information">{isLoading ? loadingContent : loadedContent}</PhoenixBaseCard>
    </Box>
  )
}

export default UnitStatInfoGutterCard
