import { useMsal } from '@azure/msal-react'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useState } from 'react'
import { useNavbar } from '../../contexts/NavbarContext'
import PhoenixLogo from '../../media/PhoenixLogo.png'
import logoutActiveUser from '../../utils/signout'
import SideDrawer from '../SideDrawer/SideDrawer'
import TopMenuAccountMenu from './styled/TopMenuSignOut'

const TopMenuBar = () => {
  const { open, handleOpen } = useNavbar()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const { instance } = useMsal()

  const handleSignOutClicked = () => {
    logoutActiveUser(instance)
  }

  const handleDrawer = () => {
    handleOpen()
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'theme.primary.main', boxShadow: 0 }}
      >
        <Toolbar>
          <IconButton
            onClick={handleDrawer}
            id="menuButton"
            sx={{ mr: 2 }}
            title="menuButton"
            size="large"
            edge="start"
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <HeaderLogo />
          <Typography title="phoenixHeader" variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Phoenix
          </Typography>

          <SideDrawer open={open} />

          <TopMenuAccountMenu
            handleMenu={handleMenu}
            anchor={anchor}
            handleClose={handleClose}
            handleSignOutClicked={handleSignOutClicked}
          />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default TopMenuBar

const HeaderLogo = () => {
  return (
    <img
      src={PhoenixLogo}
      alt="phoenixLogo"
      style={{
        width: '40px',
        height: '40px',
        flexShrink: 0,
        marginRight: '12px',
        marginLeft: '-12px',
        borderRadius: '4px',
      }}
    />
  )
}
