import { useState } from 'react'
import { AlertSeverity } from '../../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../../contexts/AlertContext'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import { updateUnitStatErrors } from '../../services/unitStatService'
import { useBoolean } from '../useBoolean'

interface UnitStatErrorModal {
  isModalOpen: boolean
  isCallLoading: boolean
  openModal: (errors: string[]) => void
  closeModal: () => void
  acceptErrors: (policyId: string) => void
  rejectErrors: (policyId: string, responseText: string) => void
  currentErrorIDs: string[]
}

const useUnitStatErrorModal = (refetchErrors: () => Promise<void>): UnitStatErrorModal => {
  const { setDetails } = useAlertContext()
  const [currentErrorIDs, setCurrentErrorIDs] = useState<string[]>([])
  const { value, onTrue, onFalse } = useBoolean()
  const { value: isCallLoading, onTrue: setIsCallLoadingTrue, onFalse: setIsCallLoadingFalse } = useBoolean()

  const openModal = (errors: string[]) => {
    onTrue()
    setCurrentErrorIDs(errors)
  }

  const closeModal = () => {
    onFalse()
    setCurrentErrorIDs([])
  }

  const acceptErrors = async (unitStatId: string) => {
    closeModal()
    const request: UpdateErrorsModel = { editIDs: currentErrorIDs, userAccepted: true }
    setIsCallLoadingTrue()

    updateUnitStatErrors(unitStatId, request)
      .then(async () => {
        setDetails({ message: 'Error(s) accepted', severity: AlertSeverity.SUCCESS })
        await refetchErrors()
      })
      .catch(() => {
        setDetails({ message: 'Update was unsuccessful. Please try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsCallLoadingFalse()
      })
  }

  const rejectErrors = async (unitStatId: string, responseText: string) => {
    closeModal()
    const request: UpdateErrorsModel = { editIDs: currentErrorIDs, userAccepted: false, responseText }
    setIsCallLoadingTrue()

    updateUnitStatErrors(unitStatId, request)
      .then(() => {
        setDetails({ message: 'Error(s) rejected and response(s) sent', severity: AlertSeverity.ERROR })
      })
      .catch(() => {
        setDetails({ message: 'Update was unsuccessful. Please try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsCallLoadingFalse()
      })
  }

  return {
    isModalOpen: value,
    isCallLoading,
    openModal,
    closeModal,
    acceptErrors,
    rejectErrors,
    currentErrorIDs, // exported for testing purposes
  }
}

export default useUnitStatErrorModal
