import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'

interface AcceptTransactionErrorModalProps {
  isOpen: boolean
  onCancel: () => void
  onAccept: () => void
}

const AcceptErrorModal = ({ isOpen, onCancel, onAccept }: AcceptTransactionErrorModalProps) => {
  return (
    <Dialog fullWidth open={isOpen} maxWidth={'xs'} data-testId={'accept-modal'}>
      <DialogContent>
        <Typography variant="subtitle2">Are you sure you want to accept this response?</Typography>
      </DialogContent>
      <DialogActions>
        <Button size={'small'} onClick={onCancel}>
          Cancel
        </Button>
        <Button size={'small'} variant="contained" color="success" onClick={onAccept}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AcceptErrorModal
