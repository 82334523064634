import { Person, Work } from '@mui/icons-material'
import { Box, Grid, Link, Typography } from '@mui/material'
import { FieldGroup, FieldGroupAddress, PhoenixBaseCard } from 'componix'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useCoverageData } from '../../contexts/CoverageDataContext'
import ControllingShield from '../../media/ControllingShield'
import { Ancestor } from '../../models/AncestorResponse'
import { handleTitleCase } from '../../utils/handleTitleCase'
import { CoverageGroupHeaderLoadingSkeleton } from './styled/CoverageGroupHeaderLoadingSkeleton'

interface PrimaryCoverageIdentifierProps {
  combo: Ancestor
  coverage: Ancestor | undefined
  title?: string
}
/**
 * PrimaryCoverageIdentifier is a left gutter card present throughout most of the Phoenix Hierarchy.
 * It displays the Entity name and Address for the primary coverage
 * (if at the combo level, this is the controlling coverage; otherwise it is the coverage whose data or children the user is viewing).
 * @param combo: Ancestor
 * @param coverage: Ancestor
 * @param title: (optional) string, defaults to "Coverage Group"
 */
export const PrimaryCoverageIdentifier = ({
  combo,
  coverage,
  title = 'Coverage Group',
}: PrimaryCoverageIdentifierProps) => {
  const {
    primaryCoverage,
    isLoadingCoverageGroupData,
    coverageDataRetrieved,
    populateCoverageData,
    errorRetrievingCoverageData,
  } = useCoverageData()

  if (!coverageDataRetrieved && !errorRetrievingCoverageData) {
    populateCoverageData(combo?.guid, coverage?.guid ?? undefined)
  }

  if (isLoadingCoverageGroupData || (primaryCoverage && primaryCoverage.coverageInfo.id != coverage?.identifier)) {
    return <CoverageGroupHeaderLoadingSkeleton />
  }
  return (
    <Box sx={{ marginTop: '16px' }}>
      <PhoenixBaseCard cardTitle={title} contentPadded={true}>
        {primaryCoverage.coverageInfo == null ? (
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" title="NoRelatedContent">
              No coverage data exists.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ padding: '8px' }}>
            <Grid container>
              <Grid item lg={6} xs={12}>
                <Box sx={{ width: 150 }}>
                  <FieldGroup
                    label={'Combo ID'}
                    value={
                      combo?.identifier && (
                        <Link
                          component={RouterLink}
                          to={`/combos/${combo?.guid}`}
                          sx={{
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {combo?.identifier}
                        </Link>
                      )
                    }
                  />
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box sx={{ width: 150 }}>
                  <FieldGroup
                    label={'Coverage ID'}
                    value={
                      coverage?.identifier && (
                        <Grid id={'ControllingShield'} item>
                          {primaryCoverage.coverageInfo.controlling && <ControllingShield />}{' '}
                          <span style={{ verticalAlign: 'top' }}>
                            <Link
                              component={RouterLink}
                              to={`/coverages/${coverage?.identifier}`}
                              sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              {coverage?.identifier}
                            </Link>
                          </span>
                        </Grid>
                      )
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <FieldGroup
              label="Primary Name"
              value={handleTitleCase(primaryCoverage.coverageInfo.name)}
              icon={<Person />}
              multiLineWrap
            />
            <FieldGroupAddress
              street={handleTitleCase(primaryCoverage.address.street)}
              city={handleTitleCase(primaryCoverage.address.city)}
              state={handleTitleCase(primaryCoverage.address.state)}
              zipCode={primaryCoverage.address.zipCode}
            />
            <FieldGroup label="FEIN" value={primaryCoverage.fein} icon={<Work />} />
          </Box>
        )}
      </PhoenixBaseCard>
    </Box>
  )
}

export default PrimaryCoverageIdentifier
