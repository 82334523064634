import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, TableWithSubrows } from 'componix'
import React from 'react'
import SplitsModel from '../../../models/SplitsModel'
import { formatMoney } from '../../../utils/moneyFormatter'
import ExposureDtoColumns from './ExposureDtoColumns'

interface SplitComponentProps {
  splitObject: SplitsModel
}

const SplitComponent = ({ splitObject }: SplitComponentProps) => {
  return (
    <>
      <Grid paddingLeft={'8px'} paddingTop={'8px'} flexDirection={'row'} container spacing={1} marginBottom={'16px'}>
        <Grid sx={{ marginLeft: '16px', marginTop: '16px' }} xs={1.97}>
          <FieldGroup label={'SPLIT INDICATOR'} value={splitObject.periodCode} />
        </Grid>
        <Grid sx={{ marginTop: '16px' }} xs={1.97}>
          <FieldGroup label={'SUBJECT PREMIUM'} value={formatMoney(splitObject.subjectPremium)} />
        </Grid>
        <Grid sx={{ marginTop: '16px' }} xs={1.97}>
          <FieldGroup label={'MODIFIED PREMIUM'} value={formatMoney(splitObject.modifiedPremium)} />
        </Grid>
        <Grid sx={{ marginTop: '16px' }} xs={1.97}>
          <FieldGroup label={'MOD EFFECTIVE'} value={splitObject.modEffectiveDate} />
        </Grid>
        <Grid sx={{ marginTop: '16px' }} xs={1.97}>
          <FieldGroup label={'RATE EFFECTIVE'} value={splitObject.rateEffectiveDate} />
        </Grid>
        <Grid sx={{ marginTop: '16px' }} xs={1.9}>
          <FieldGroup label={'MOD FACTOR'} value={splitObject.modFactor} />
        </Grid>
      </Grid>
      <Grid
        paddingLeft={'8px'}
        data-testid={'secondary-cards'}
        paddingTop={'8px'}
        flexDirection={'column'}
        container
        spacing={1}
      >
        <Grid sx={{ marginLeft: '4px', marginRight: '4px' }}>
          <PhoenixBaseCard collapsible={true} variantType="Secondary" cardTitle={'Subject to Mod'}>
            <TableWithSubrows
              data-testid={'subrow-subjecttomod'}
              columns={ExposureDtoColumns}
              data={splitObject.subjectToMod}
            />
          </PhoenixBaseCard>
        </Grid>
        <Grid sx={{ marginLeft: '4px', marginRight: '4px' }}>
          <PhoenixBaseCard collapsible={true} variantType="Secondary" cardTitle={'Not Subject to Mod'}>
            <TableWithSubrows
              data-testid={'subrow-not-subjecttomod'}
              columns={ExposureDtoColumns}
              data={splitObject.notSubjectToMod}
            />
          </PhoenixBaseCard>
        </Grid>
        <Grid sx={{ marginLeft: '4px', marginRight: '4px' }}>
          <PhoenixBaseCard collapsible={true} variantType="Secondary" cardTitle={'Non Standard'}>
            <TableWithSubrows
              data-testid={'subrow-nonstandard'}
              columns={ExposureDtoColumns}
              data={splitObject.nonStandard}
            />
          </PhoenixBaseCard>
        </Grid>
      </Grid>
    </>
  )
}
export default SplitComponent
