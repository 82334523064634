import { ReactNode, createContext, useContext, useState } from 'react'
import React from 'react'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'

type AlertDetails = {
  message: string
  severity: AlertSeverity
}

interface AlertContextProps {
  details: AlertDetails
  setDetails: (details: AlertDetails) => void
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined)

const useAlertContext = (): AlertContextProps => {
  const context = useContext(AlertContext)

  if (!context) {
    throw new Error('useAlertContext must be used within a AlertContextProvider')
  }

  return context
}

interface AlertContextProviderProps {
  children: ReactNode
}

const AlertContextProvider: React.FC<AlertContextProviderProps> = ({ children }) => {
  const [details, setDetails] = useState({ message: '', severity: AlertSeverity.ERROR })

  return <AlertContext.Provider value={{ details: details, setDetails: setDetails }}>{children}</AlertContext.Provider>
}

export { AlertContextProvider, useAlertContext }
