import { PhoenixBaseCard } from 'componix'
import React from 'react'
import { useNavbar } from '../contexts/NavbarContext'

export const WorkQueue = () => {
  const { marginLeft } = useNavbar()

  return (
    <div
      id="work-queue"
      style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginTop: '82px', marginRight: '24px' }}
    >
      <PhoenixBaseCard cardTitle="My Work Queue"></PhoenixBaseCard>
    </div>
  )
}
