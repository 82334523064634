import { Box, Grid, Link, Typography } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCoverageData } from '../../contexts/CoverageDataContext'
import ControllingShield from '../../media/ControllingShield'

interface RelateCoverageIDsCardProps {
  isLoading: boolean
}

export const RelatedCoverageIDsCard = ({ isLoading }: RelateCoverageIDsCardProps) => {
  const [cardMaxHeight, setCardMaxHeight] = React.useState<number | undefined>(undefined)

  const navigate = useNavigate()

  const handleCoverageIDClick = (value: string | null) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(`/coverages/${value}`)
  }
  const { relatedCoverages } = useCoverageData()

  const relatedCoveragesKebabMenuItems = [{ label: 'View All Coverages', to: '/', disabled: true }]

  useEffect(() => {
    if (relatedCoverages?.length > 2) {
      setCardMaxHeight(150)
    }
  }, [relatedCoverages])

  return (
    <PhoenixBaseCard
      cardTitle={'Related Coverage IDs'}
      isLoading={isLoading}
      contentPadded={true}
      kebabMenuItems={relatedCoveragesKebabMenuItems}
    >
      <Box maxHeight={cardMaxHeight} padding="8px" sx={{ overflowY: 'auto' }}>
        {relatedCoverages == undefined || relatedCoverages?.length == 0 ? (
          <Typography variant="body1" title="NoRelatedContent">
            No other Coverage IDs exist.
          </Typography>
        ) : (
          relatedCoverages.map((row) => (
            <>
              <Grid container>
                <Grid item xs={6}>
                  <FieldGroup
                    label={'Coverage ID'}
                    value={
                      <Link
                        id={'link'}
                        underline="hover"
                        component="button"
                        onClick={(event) => handleCoverageIDClick(event.currentTarget.textContent)}
                        sx={{ padding: 0, textAlign: 'left' }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: '500' }}>
                          {
                            <span>
                              {row.controlling && <ControllingShield />}
                              <span style={{ verticalAlign: 'top' }}>{row.id}</span>
                            </span>
                          }
                        </Typography>
                      </Link>
                    }
                  />
                </Grid>
                <Grid item xs={6} zeroMinWidth>
                  <FieldGroup label={'Name'} value={row.name} multiLineWrap={true} />
                </Grid>
              </Grid>
            </>
          ))
        )}
      </Box>
    </PhoenixBaseCard>
  )
}

export default RelatedCoverageIDsCard
