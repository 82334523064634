import PolicyDetailsSummaryModel from './PolicyDetailSummaryModel'
import PolicyDetailsInformationModel from './PolicyDetailsInformationModel'
import PolicyDetailsProducerModel, { EmptyProducerModel } from './PolicyDetailsProducerModel'

export interface PolicyDetailsResponseModel {
  summary: PolicyDetailsSummaryModel
  information: PolicyDetailsInformationModel
  producer: PolicyDetailsProducerModel
}

export const EmptyPolicyDetailsResponse: PolicyDetailsResponseModel = {
  summary: {
    carrierId: '',
    carrierName: '',
    policyNumber: '',
    effectiveDate: '',
    expirationDate: '',
    status: '',
    statusDate: '',
    issueDate: '',
    receivedDate: '',
    outstandingErrors: false,
  },
  information: {
    policyType: '',
    employeeLeasingType: '',
    isMultistate: false,
    isWrapUp: false,
    policyTermCode: '',
    stateEffectiveDate: '',
  },
  producer: EmptyProducerModel,
}
