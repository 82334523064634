import { RatingDetailsModel } from '../models/RatingDetailsModel'
import { RatingInfoModel } from '../models/RatingInfoModel'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getRatingDetails = async (ratingId: string) => {
  const response: ApiResponse<RatingDetailsModel> = await api.get(`${Endpoints.Ratings}/${ratingId}`)
  return response.data
}

export const getRatingInfoByComboId = async (comboId: string) => {
  const response: ApiResponse<RatingInfoModel> = await api.get(`${Endpoints.Ratings}/topBy`, {
    params: { comboId },
  })

  return response.data
}
