import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material'
import React from 'react'

const LoadingSkeletons = () => {
  return (
    <Card
      data-testid="loading-skeletons"
      sx={{
        // minHeight: '300px',
        flexShrink: 0,
        borderRadius: 'var(--1, 8px)',
        backgroundColor: '#fafafa',
      }}
    >
      {' '}
      <CardHeader sx={{ backgroundColor: '#e0e0e0' }} title={''} />
      <CardContent
        sx={{ paddingTop: '4px', paddingLeft: '16px', paddingRight: '8px', '&:last-child': { paddingBottom: 0 } }}
      >
        <Grid container spacing={2}>
          <Grid item md={2}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'120px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton variant="rounded" height={'20px'} sx={{ marginTop: '5px', marginBottom: '5px' }} />
            <Skeleton variant="rounded" height={'20px'} sx={{ marginTop: '5px', marginBottom: '5px' }} />
            <Skeleton variant="rounded" height={'20px'} sx={{ marginTop: '5px', marginBottom: '5px' }} />
            <Skeleton variant="rounded" height={'20px'} sx={{ marginTop: '5px', marginBottom: '5px' }} />
          </Grid>
          <Grid item md={2}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'120px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
          <Grid item md={2}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'120px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
          <Grid item md={2}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'120px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
          <Grid item md={2}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'120px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
          <Grid item md={2}>
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'120px'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
            <Skeleton
              variant="rounded"
              height={'20px'}
              width={'180px'}
              sx={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default LoadingSkeletons
