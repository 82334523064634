import React from 'react'

export default function ControllingShield() {
  return (
    <span style={{ marginRight: '5px' }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="#ECEFF1" />
        <path
          d="M-288 -477C-288 -478.105 -287.105 -479 -286 -479H4558C4559.1 -479 4560 -478.105 4560 -477V1975C4560 1976.1 4559.1 1977 4558 1977H-286C-287.105 1977 -288 1976.1 -288 1975V-477Z"
          fill="white"
        />
        <path
          d="M-286 -478H4558V-480H-286V-478ZM4559 -477V1975H4561V-477H4559ZM4558 1976H-286V1978H4558V1976ZM-287 1975V-477H-289V1975H-287ZM-286 1976C-286.552 1976 -287 1975.55 -287 1975H-289C-289 1976.66 -287.657 1978 -286 1978V1976ZM4559 1975C4559 1975.55 4558.55 1976 4558 1976V1978C4559.66 1978 4561 1976.66 4561 1975H4559ZM4558 -478C4558.55 -478 4559 -477.552 4559 -477H4561C4561 -478.657 4559.66 -480 4558 -480V-478ZM-286 -480C-287.657 -480 -289 -478.657 -289 -477H-287C-287 -477.552 -286.552 -478 -286 -478V-480Z"
          fill="black"
          fillOpacity="0.1"
        />
        <rect width="1440" height="1799" transform="translate(-148 -350)" fill="#F6F7F9" />
        <g filter="url(#filter0_d_1204_9402)">
          <rect width="300" height="64" transform="translate(-124 -32)" fill="white" />
          <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
          <path
            d="M10.5 15.75L7.5 12.75L8.5575 11.6925L10.5 13.6275L15.4425 8.685L16.5 9.75M12 3.75L5.25 6.75V11.25C5.25 15.4125 8.13 19.305 12 20.25C15.87 19.305 18.75 15.4125 18.75 11.25V6.75L12 3.75Z"
            fill="#2E7D32"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1204_9402"
            x="-126"
            y="-80"
            width="320"
            height="356"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1204_9402" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1204_9402" result="shape" />
          </filter>
        </defs>
      </svg>
    </span>
  )
}
