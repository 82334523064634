import { ComboIdResponse } from '../models/ComboIdResponse'
import { ComboCoverageResponse } from '../models/CoverageGroupModels'
import { SearchByFeinResponse } from '../models/SearchByFeinResponse'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getComboDataByCoverageId = async (coverageId: string): Promise<ComboIdResponse> => {
  const response: ApiResponse<ComboIdResponse> = await api.get(`${Endpoints.ComboId}/${coverageId}/combo`)
  return response.data
}

export const getCoverageDataByCoverageId = async (
  comboId: string,
  primaryCoverageId?: string
): Promise<ComboCoverageResponse> => {
  let endpoint = `${Endpoints.Combos}/${comboId}/coverages`
  if (primaryCoverageId != undefined) {
    endpoint = endpoint.concat(`?primary=${primaryCoverageId}`)
  }
  const response: ApiResponse<ComboCoverageResponse> = await api.get(endpoint)
  return response.data
}

export const searchByFEIN = async (fein: string): Promise<SearchByFeinResponse[]> => {
  const response: ApiResponse<SearchByFeinResponse[]> = await api.get(`${Endpoints.Coverages}/SearchByFein/${fein}`)
  return response.data
}

export const searchByName = async (name: string): Promise<SearchByFeinResponse[]> => {
  const response: ApiResponse<SearchByFeinResponse[]> = await api.get(`${Endpoints.Coverages}/SearchByName/${name}`)
  return response.data
}
