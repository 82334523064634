import { DataFieldBlankValue } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import PolicyDetailsEndorsementsModel from '../../../models/PolicyDetails/PolicyDetailsEndorsementsModel'
import { booleanToYN } from '../../../utils/booleanToYN'

/* eslint react/prop-types: 0 */
export const PolicyEndorsementsTableColumns: MRT_ColumnDef<PolicyDetailsEndorsementsModel>[] = [
  {
    accessorKey: 'endorsement',
    header: 'Endorsement Number',
    Cell: ({ cell }) => <>{cell.getValue() ? cell.getValue() : <DataFieldBlankValue />}</>,
  },
  {
    accessorKey: 'endorsementName',
    header: 'Endorsement Name',
    size: 250,
  },
  {
    accessorKey: 'bureauVersionId',
    header: 'Bureau Version',
  },
  {
    accessorKey: 'carrierVersionId',
    header: 'Carrier Version',
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective',
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration',
  },
  {
    accessorKey: 'receivedDate',
    header: 'Received',
  },
  {
    accessorKey: 'issuedDate',
    header: 'Issued',
  },

  {
    accessorKey: 'extensionRequired',
    header: 'Extension',

    Cell: ({ cell }) => <>{booleanToYN(cell.getValue<boolean>())}</>,
  },
]

export default PolicyEndorsementsTableColumns
